import { Component, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import swal from "sweetalert2";
import { FileService } from "src/app/services/file.service";
import { File } from "src/app/models/file";
import { User } from "src/app/models/user";
import { FolderService } from '../../services/folder.service';
import { ProjectService } from "src/app/services/project.service";
import { ClientService } from "src/app/services/client.service";
import { environment } from '../../../environments/environment';

const uuid = require('uuid');

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"],
  providers: [FileService, FolderService, ProjectService, ClientService]
})

export class FileComponent implements OnInit {
  loading;
  // variables para mostrar u ocultar el contenedor
  public userRole: number;
  public userCode: number;
  public contentQR: boolean;
  public qrGenerado: boolean;
  public closeResult: string;
  public imageQR: string;
  public state: any = {};
  @ViewChild('input', { static: false }) input: ElementRef;
  public qr: boolean;
  public isDisabled: boolean;

  // variables que almacenan información de usuario y archivos
  public file: File;
  public folder: any = {};
  public project: any = {};
  public client: any = {};

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public fileList: File[] = new Array();

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private _route: ActivatedRoute,
    private _fileService: FileService,
    private modalService: NgbModal,
    private _folderService: FolderService,
    private _projectService: ProjectService,
    private _clientService: ClientService,
    @Inject(DOCUMENT) document: any
  ) {
    this.file = new File(null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    // this.folder = new Folder(null, null, null, null, null);
  }

  async ngOnInit() {
    // inicializa el contenedor principal
    this.contentQR = false;
    this.qrGenerado = false;

    // Ingreso a variables de información de proyecto
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }

    this.file.fileUser = _user.userCode;
    this.userRole = _user.userRole ? parseInt(_user.userRole.toString()) : 0;
    this.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.isDisabled = false;

    this._route.paramMap.subscribe(async (params: any) => {
      const folderCode = params.params.folderCode;

      if (folderCode !== undefined) {

        // this.file.fileFolder = folderCode;
        this.getFilesByFolders(folderCode);
        await this.getFolder(folderCode);
        await this.getProject(this.folder.folderProject);
        await this.getClient(this.project.projectClient);
      }

    });

    // funciones iniciales


  }

  // funciones para upload de archivos
  getUploadType() {

    if (this.contentQR == false) {
      this.contentQR = true;
    } else {
      this.contentQR = false;
    }

  }

  getUploadQR(content) {
    this.qrGenerado = true;

    const myId = uuid.v4();
    this.file.fileQR = myId;

    this._fileService.getQR(myId).subscribe(
      response => {
        //console.log(response);
        this.imageQR = response;
      },
      error => {
        console.log(error);
      }
    );

    this.modalService.open(content).result.then(
      result => {
        //this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

    //console.log(myId);
  }


  onFocus(e) {
    this.input.nativeElement.click(e);
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  addFile(e) {
    //console.log(e);
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;

  }

  // funciones para datatable
  getFilesByFolders(folderCode) {
    this.loading = true;
    this._fileService.getAllByFolder(folderCode).then(
      response => {
        if (response.result == true) {
          this.fileList = response.records;
          this.temp = this.fileList;
          //console.log(response);
        }
      },
      error => {
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.fileList.filter(function (d) {

      for (var key in d) {
        //console.log(d[key]);

        if(d[key] !== null){

          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }

        }

      }
      return false;
    });
  }

  onSelect($event) {
    console.log('Select Event', $event);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  viewPDF($event) {
    $event.preventDefault();

    const pathUrl = document.location.href;

    //console.log(this.activeRow.fileKey);

    window.open('view-pdf?fileKey=' + this.activeRow.fileKey, '_blank');
  }

  // Funciones para manipulación de datos
  getFolder(folderCode) {
    this.loading = true;
    return this._folderService.getOne(folderCode).then(
      response => {
        //console.log(response.records);
        this.folder = response.records[0];
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  getProject(projectCode) {
    this.loading = true;
    return this._projectService.getOne(projectCode).then(
      response => {
        if (response.result == true) {
          this.project = response.records[0];
          //console.log(this.project);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  getClient(clientCode) {
    this.loading = true;
    return this._clientService.getOne(clientCode).then(
      response => {
        if (response.result == true) {
          this.client = response.records[0];
          //console.log(this.client);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  convertBase64(file) {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  }

  async save() {
    this.isDisabled = true;

    //console.log(this.state.files);
    const file = this.state.files.item(0);

    await this.convertBase64(file).then(
      (data: string) => {
        this.file.fileObject = data;
        // console.log(this.file.fileObject)/* return data*/;
      }
    );

    this.file.fileStatus = 1;
    this.file.fileName = this.state.fileNames;
    //console.log(this.file)

    //console.log(this.folderDefault);
    this._fileService.insert(this.file).subscribe(
      async (response) => {
        if (response.result == true) {
          //console.log(response);

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Realizar la actualización de la tabla
          // this.getFilesByFolders(this.file.fileFolder);

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );

  }

  update() {
    this.isDisabled = true;

    // this.folder.folderStatus = 1;
    // console.log(this.folder);
    this._fileService.update(this.file).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          //limpiar el modelo
          this.cleanModel();

          // Realizar la actualización de la tabla
          // this.getFilesByFolders(this.file.fileFolder);

          // Mostrar notificación
          this.showNotification(1, response.message);

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  deleteFunction($event) {
    $event.preventDefault();

    this.file.fileCode = this.activeRow.fileCode,
      // this.file.fileFolder = this.activeRow.fileFolder,
      this.file.fileName = undefined,
      this.file.fileExtension = undefined,
      this.file.fileUser = undefined,
      this.file.fileKey = undefined,
      this.file.fileQR = undefined,
      this.file.fileStatus = undefined,
      this.file.fileDeleted = 1,
      this.file.fileObject = undefined

    //console.log(this.client);

    swal.fire({
      title: "Esta seguro",
      text: "El Archivo se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        //console.log(this.activeRow.folderProject);
        //this.getFoldersByProject(this.activeRow.folderProject);
        swal.fire({
          title: "Eliminado!",
          text: "El Archivo se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  cleanModel() {
    
    this.isDisabled = false;
    // this.message.messageTo = null;
    // this.message.messageSubject = null;
    // this.message.messageBody = null;

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
