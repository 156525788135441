import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { User } from '../models/user';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { Password } from '../models/password';
import { ToastrService } from 'ngx-toastr';

const POOL_DATA = {
  UserPoolId: environment.userPoolId,
  ClientId: environment.clientId,
};
const userPool = new CognitoUserPool(POOL_DATA);
@Injectable()
export class UserService {
  private succedded = false;
  public authStatusChanged = new Subject<boolean>();
  public loggedIn: boolean;

  public url: string;

  constructor(
    private _http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.url = environment.apiUrl;
  }

  // Obtiene el listado de usuarios completo
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers }).toPromise();
  }

  // Obtiene el listado de usuarios si son admin o clientes
  getAllByRole(role): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-user/role/' + role, { headers: headers })
      .toPromise();
  }

  // Obtiene el listado de usuarios solo codigo y nombre
  getAllList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'isocretosystem-user/list/', {
      headers: headers,
    });
  }

  // Obtiene un solo usuario a la vez
  getOne(companyCode): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '' + companyCode, { headers: headers });
  }

  // Inserta un usuario
  insert(user: User): Observable<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'isocretosystem-user/cognito', params, {
      headers: headers,
    });
  }

  // Actualiza un usuario
  update(user: User): Observable<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url + 'isocretosystem-user/cognito', params, {
      headers: headers,
    });
  }

  // Actualiza password
  async updatePassword(
    username,
    previousPassword,
    proposedPassword
  ): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    const accessToken: any = await this.getAccessToken(
      username,
      previousPassword
    );

    const params = {
      accessToken: accessToken.accessToken.jwtToken,
      previousPassword: previousPassword,
      proposedPassword: proposedPassword,
    };

    return this._http
      .post(this.url + 'isocretosystem-user/changePassword/', params, {
        headers: headers,
      })
      .toPromise();
  }

  getAccessToken(username, password) {
    const POOL_DATA = {
      UserPoolId: environment.userPoolId,
      ClientId: environment.clientId,
    };
    const userPool = new CognitoUserPool(POOL_DATA);
    return new Promise((resolve, reject) => {
      userPool.getCurrentUser().getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  signIn(username: string, password: string): any {
    const authData = {
      Username: username,
      Password: password,
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    this.succedded = false;
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: any) {
        that.succedded = true;
        that.authStatusChanged.next(true);
        that.showNotification(1, 'Bienvenido ' + result.idToken.payload.name);
        const userLogged = {
          userEmail: result.idToken.payload.email,
          userCognitoCode: result.idToken.payload['cognito:username'],
          userName: result.idToken.payload.name,
          userPassword: undefined,
          userStatus: undefined,
        };
        localStorage.setItem('user', JSON.stringify(userLogged));
        that.router.navigate(['']);
      },
      onFailure(err: any) {
        that.authStatusChanged.next(false);
        that.showNotification(3, 'Usuario o Contraseña Inválidos!');
      },
    });

    this.authStatusChanged.next(this.succedded);
  }

  async logout() {
    await this.signOut();
    // this.getAuthenticatedUser().signOut();
    this.authStatusChanged.next(false);
    localStorage.clear();
  }

  signOut(): Promise<any> {
    return Auth.signOut().then(() => (this.loggedIn = false));
  }

  oneUserCognitoBackend(userCognitoCode): Promise<any> {
    const filter = userCognitoCode;
    // const openedSession = this.getAuthenticatedUserSession();
    // const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    // const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this._http
      .get(environment.apiUrl + 'isocretosystem-user/cognito/' + filter)
      .toPromise();
  }

  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = new Observable<boolean>((observer) => {
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if (err) {
            observer.next(false);
          }
          if (session.isValid()) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      }
      observer.complete();
    });
    return obs;
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }

  initAuth() {
    this.isAuthenticated().subscribe((auth) =>
      this.authStatusChanged.next(auth)
    );
  }

  confirm(user): Promise<any> {
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .post(this.url + 'isocretosystem-user/confirm', params, {
        headers: headers,
      })
      .toPromise();
  }

  oneUserEmailBackend(userEmail): Promise<any> {
    const filter = userEmail;
    // const openedSession = this.getAuthenticatedUserSession();
    // const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    // const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this._http
      .get(environment.apiUrl + 'isocretosystem-user/email/' + filter)
      .toPromise();
  }

  // Notificaciónes
  showNotification(type, message) {
    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-success alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-warning alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' +
            message,
          '',
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-danger alert-with-icon',
            positionClass: 'toast-top-right',
          }
        );
        break;
    }
  }
}
