<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home&nbsp;</a>
        </div>
        <div *ngIf="contentPrincipal">
            /<a> Clientes</a>
        </div>
        <div *ngIf="contentViewClientI || contentCrudClientI">
            /<a routerLink="/client/0"> Clientes </a>/<a> Individuales</a>
        </div>
        <div *ngIf="contentViewClientJ || contentCrudClientJ">
            /<a routerLink="/client/0"> Clientes </a>/<a> Juridicos</a>
        </div>
    </div>
</div>
<!-- Contenedor principal -->
<div class="main-content" *ngIf="contentPrincipal">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="statistics">
                                <a (click)="getViewClientI();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-male fa-5x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Cliente individual</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="statistics">
                                <a (click)="getViewClientJ();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-sitemap fa-5x"></i>
                                        </div>
                                        <h6 class="stats-title">Cliente jurídico</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contenedor ver cliente individual -->
<div class="main-content" *ngIf="contentViewClientI">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getClient();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudClientI();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar cliente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de clientes individuales</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Mostrar
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">

                            <ngx-datatable-column name="Nit"></ngx-datatable-column>
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column name="Correo"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like" (click)="getProjects($event)"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="editFunction($event)"><i class="fas fa-pencil-alt"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedor crud cliente individual -->
<div class="main-content" *ngIf="contentCrudClientI">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getViewClientI();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudClientI();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar cliente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title" *ngIf="!isEdit">Crear cliente</h4>
                    <h4 class="card-title" *ngIf="isEdit">Modificar cliente</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <!-- <div class="col-md-4">
                            <label>DPI</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientDocument" />
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <label>NIT</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Nombre</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientName" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Teléfono</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientPhone" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Email</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientEmail" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Dirección</label>
                                <textarea rows="2" cols="80" class="form-control" placeholder="" [(ngModel)]="client.clientAddress"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-success" (click)="update(1)" *ngIf="isEdit" [disabled]="isDisabled">Modificar</button>
                    <button type="button" class="btn btn-success" (click)="save(1)" *ngIf="!isEdit" [disabled]="isDisabled">Guardar</button>
                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedor ver cliente Juridico -->
<div class="main-content" *ngIf="contentViewClientJ">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getClient();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudClientJ();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar cliente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de clientes juridicos</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">

                            <ngx-datatable-column name="Empresa"></ngx-datatable-column>
                            <!-- <ngx-datatable-column name="Nombre"></ngx-datatable-column> -->
                            <ngx-datatable-column name="Telefono"></ngx-datatable-column>
                            <ngx-datatable-column name="Correo"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like" (click)="getProjects($event)"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="editFunction($event)"><i class="fas fa-pencil-alt"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedor crud cliente Juridico -->
<div class="main-content" *ngIf="contentCrudClientJ">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getViewClientJ();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudClientJ();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar cliente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title" *ngIf="!isEdit">Crear cliente</h4>
                    <h4 class="card-title" *ngIf="isEdit">Modificar cliente</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Nombre empresa</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientCompany" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>NIT empresa</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientTaxDocument" />
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <label>Nombre representante</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientName" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>DPI representante</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientDocument" />
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <label>Teléfono</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientPhone" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Email</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="client.clientEmail" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label>Dirección</label>
                            <div class="form-group">
                                <textarea rows="2" cols="80" class="form-control" placeholder="" [(ngModel)]="client.clientAddress"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <button type="button" class="btn btn-success" (click)="update(2)" *ngIf="isEdit" [disabled]="isDisabled">Modificar</button>
                    <button type="button" class="btn btn-success" (click)="save(2)" *ngIf="!isEdit" [disabled]="isDisabled">Guardar</button>
                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>