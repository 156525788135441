<!-- <div class="wrapper">
    <app-sidebar *ngIf="isLogged == true"></app-sidebar>
    <div [class]="isLogin == false && isLogged == true ? 'main-panel' : 'full-page section-image'">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div> -->
<app-loading *ngIf="loading"></app-loading>
<div class="wrapper wrapper-full-page ">
    <app-sidebar *ngIf="isLogged == true"></app-sidebar>
    <div [class]="isLogin == false && isLogged == true ? 'main-panel' : 'full-page section-image'" filter-color="black">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>