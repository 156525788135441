export class File{
	constructor(
        public fileCode: number,
        public fileProject: number,
        public fileLevel: number,
        public fileParent: number,
        public fileName: string,
        public fileExtension: string,
        public fileUser: number,
        public fileKey: string,
        public fileQR: string,
        public fileDate: string,
        public fileStatus: number,
        public fileDeleted: number,
        public fileObject: string,
        public fileSignUrl: string
	){}
}