<div class="panel-header panel-header-sm">

</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="statistics">
                                <div class="info">
                                    <a (click)="newScann();">
                                        <div class="icon icon-default">
                                            <i class="fa fa-qrcode"></i>
                                            <h6 class="stats-title">Acerca el código QR</h6>
                                        </div>
                                    </a>
                                    <hr />
                                    <div class="col-sm-12">
                                        <label>Selecciona la cámara</label>
                                        <angular2-multiselect [data]="listDevices" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                                        </angular2-multiselect>
                                    </div>
                                    <p>&nbsp;</p>
                                </div>
                                <qr-scanner *ngIf="!QRDetected" [debug]="false" [canvasWidth]="320" [canvasHeight]="480" [stopAfterScan]="true" [updateTime]="500">
                                </qr-scanner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>