import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Client } from '../models/client';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClientService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
  }

  // Obtiene el listado de clientes completo
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers }).toPromise();
  }

  // Obtiene el listado de clientes si son individuales o juridicos
  getAllByType(type): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-client/type/' + type, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de clientes solo codigo y nombre
  getAllList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'isocretosystem-client/list', {
      headers: headers,
    });
  }

  // Obtiene un solo cliente a la vez
  getOne2(cognitoCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-client/user/' + cognitoCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene un solo cliente a la vez
  getOne(clientCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-client/one/' + clientCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Inserta un cliente
  insert(client: Client): Observable<any> {
    let params = JSON.stringify(client);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'isocretosystem-client/', params, {
      headers: headers,
    });
  }

  // Actualiza un cliente
  update(client: Client): Observable<any> {
    let params = JSON.stringify(client);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url + 'isocretosystem-client/', params, {
      headers: headers,
    });
  }
}
