export class Folder{
	constructor(
        public folderCode: number,
        public folderProject: number,
        public folderLevel: number,
        public folderParent: number,
        public folderType: number,
        public folderName: string,
        public folderStatus: number,
        public folderDeleted: number,
        // variables para mostrar
        public nombre: string,
        public fileDateOnly: any,
        public fileKey: string
	){}
}