import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Message } from "src/app/models/message";
import { User } from "src/app/models/user";
import { MessageService } from "src/app/services/message.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
  providers: [UserService, MessageService]
})

export class ContactComponent implements OnInit {
  // variables para mostrar u ocultar el contenedor
  public contentSend: boolean;
  public contentRead: boolean;
  public contentInbox: boolean;
  public contentOutbox: boolean;
  public contentAnswer: boolean;

  // variables que almacenan información de usuario y mensajes
  public userRole: number;
  public userCode: number;
  public message: any;
  public readMessage: boolean;

  // variables para select
  public userList: User[];
  public selectedItems = [];
  public dropdownSettings = {};

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public messageList: Message[] = new Array();

  constructor(
    private cdref: ChangeDetectorRef,
    private _userService: UserService,
    private _messageService: MessageService,
    private toastr: ToastrService
  ) {
    this.message = new Message(null, null, null, null, null, null, null, null, null, null, null)
  }

  async ngOnInit() {
    // inicializa el contenedor principal
    this.contentInbox = true;

    // Ingreso a variables de información de usuario
    this.userRole = 1;
    this.userCode = 3;
    this.message.messageFrom = this.userCode;

    // Select
    this.getUserList();
    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };

    // funciones primarias
    await this.getMessages(1);

  }

  // funciones para cambiar entre contenedores
  async getInbox() {
    await this.getMessages(1);
    this.contentInbox = true;
    this.contentSend = false;
    this.contentRead = false;
    this.contentOutbox = false;
    this.contentAnswer = false;
  }

  async getOutbox() {
    await this.getMessages(2);
    this.contentInbox = false;
    this.contentSend = false;
    this.contentRead = false;
    this.contentOutbox = true;
    this.contentAnswer = false;
  }

  getSend() {

    if (this.userRole == 2) {
      this.message.messageTo = 3;
    }

    this.contentInbox = false;
    this.contentSend = true;
    this.contentRead = false;
    this.contentOutbox = false;
    this.contentAnswer = false;
  }

  getOne(messageCode) {

    this._messageService.getOne(messageCode).then(
      response => {
        if (response.result == true) {
          this.message = response.records[0];
          //console.log(response.records[0]);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  async getRead($event) {
    $event.preventDefault();

    await this.getOne(this.activeRow.messageCode);

    this.contentInbox = false;
    this.contentSend = false;
    this.contentRead = true;
    this.contentOutbox = false;
    this.contentAnswer = false;
  }

  getAnswer() {
    this.contentAnswer = true;
  }

  // funciones para select
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  getUserList() {
    this._userService.getAllList().subscribe(
      response => {
        if (response.result == true) {
          this.userList = response.records;
          //console.log(response);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onItemSelect(item: any) {

    this.message.messageTo = item.id;
    // console.log(this.message.messageTo);
    // console.log(this.selectedItems);
  }


  // funciones para datatable
  getMessages(type) {

    if (type == 1) {
      this._messageService.getInbox(this.userCode).then(
        response => {
          if (response.result == true) {
            this.messageList = response.records;
            this.temp = this.messageList;
            //console.log(response);
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this._messageService.getOutbox(this.userCode).then(
        response => {
          if (response.result == true) {
            this.messageList = response.records;
            this.temp = this.messageList;
            //console.log(this.temp);
          }
        },
        error => {
          console.log(error);
        }
      );
    }

  }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.messageList.filter(function (d) {

      for (var key in d) {
        if (d[key].toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }

  onSelect($event) {
    console.log('Select Event', $event);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  deleteFunction($event) {

  }

  // Funciones para manipulación de datos
  save() {

    this.message.messageResponseCode = 0;
    this.message.messageOutstanding = 0;
    this.message.messageStatus = 1;

    // console.log(this.message);
    this._messageService.insert(this.message).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Ir hacia outbox
          this.getOutbox();

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  cleanModel() {

    this.message.messageTo = null;
    this.message.messageSubject = null;
    this.message.messageBody = null;

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
