import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';
import swal from "sweetalert2";
import { FolderService } from "src/app/services/folder.service";
import { Folder } from "src/app/models/folder";
import { FolderDefault } from "src/app/models/folder-default";
import { User } from "src/app/models/user";
import { Project } from "src/app/models/project";
import { ProjectService } from "src/app/services/project.service";
import { Client } from "src/app/models/client";
import { ClientService } from "src/app/services/client.service";
import { FileService } from "src/app/services/file.service";
import { File } from "src/app/models/file";
import { Calendar } from 'fullcalendar';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from '../../../environments/environment';
const uuid = require('uuid');
declare const require: any;
const FullCalendar = require('fullcalendar')
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-calendar-folder",
  templateUrl: "./calendar-folder.component.html",
  styleUrls: ["./calendar-folder.component.scss"],
  providers: [FolderService, ProjectService, ClientService, FileService]
})

export class CalendarFolderComponent implements OnInit {
  loading;
  model: NgbDateStruct;
  // variables para mostrar u ocultar el contenedor
  public userRole: number;
  public userCode: number;
  public folder: Folder;
  public clientCode: number;
  public isDisabled: boolean;
  public contentQR: boolean;
  public qrGenerado: boolean;
  public imageQR: string;
  public state: any = {};
  public level: number;
  public now;

  // variables que almacenan información de usuario y folders
  public folderDefault: FolderDefault;
  public folderActual: Folder;
  public folderCode;
  public projectCode;

  // variables para manejo de datos
  public project: Project;
  public client: Client;
  public file: File;
  public fileList: any[] = new Array();
  public eventsCalendar = new Array();
  public folderList: Folder[] = new Array();

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;

  constructor(
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private router: Router,
    private _route: ActivatedRoute,
    private _folderService: FolderService,
    private _projectService: ProjectService,
    private _clientService: ClientService,
    private _fileService: FileService,
    private modalService: NgbModal,
  ) {
    this.folderDefault = new FolderDefault(null, null, null);
    this.folder = new Folder(null, null, null, null, null, null, null, null, null, null, null);
    this.folderActual = new Folder(null, null, null, null, null, null, null, null, null, null, null);
    this.project = new Project(null, null, null, null, null, null, null, null, null);
    this.client = new Client(null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.file = new File(null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  async ngOnInit() {
    // inicializa el contenedor principal
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.now = yyyy + '-' + mm + '-' + dd;
    this.isDisabled = false;
    this.contentQR = false;
    this.qrGenerado = false;

    this.userRole = _user.userRole ? parseInt(_user.userRole.toString()) : 0;
    this.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.clientCode = _user.userClient;

    // Ingreso a variables de información de proyecto
    this._route.paramMap.subscribe(async (params: any) => {
      this.projectCode = params.params.projectCode;
      this.folderCode = params.params.folderCode;
      this.level = params.params.level == undefined ? 0 : params.params.level;

      if (this.projectCode !== undefined) {

        this.folder.folderProject = this.projectCode;
        this.file.fileProject = this.projectCode;
        this.file.fileParent = this.projectCode;
        this.folder.folderLevel = 0;
        this.file.fileLevel = 0;
        this.folder.folderParent = this.projectCode;
        await this.getProject(this.projectCode);
        await this.getClient(this.project.projectClient);
        await this.getFiles(this.project.projectClient);

        if (this.folderCode !== undefined) {

          await this.getFolder(this.folderCode);
          // console.log(projectCode, folderCode);
          await this.getFoldersByFolder(this.projectCode, this.folderCode);
          this.folder.folderParent = this.folderCode;
          this.file.fileParent = this.folderCode;
          this.level = parseInt(this.level.toString()) + 1;
          this.file.fileLevel = this.level
          this.folder.folderLevel = this.level;

          // recorrer la lista para transformarla en el formato del calendario
          this.folderList.forEach(f => {

            const title = f.nombre;
            const allDay = true;
            const className = 'event-default';
            const color = 'blue';
            const fecha = f.fileDateOnly;
            const url = f.fileKey;

            const _fecha = fecha.split("-");

            this.eventsCalendar.push({
              title: title,
              start: new Date(_fecha[0], _fecha[1] - 1, _fecha[2]),
              end: new Date(_fecha[0], _fecha[1] - 1, _fecha[2]),
              allDay: allDay,
              url: url,
              className: className,
              color: color
            });

          });

        }

        //console.log(this.eventsCalendar);
        this.goToCalendar(this.eventsCalendar);

      }

    });

    this.goToCalendar

  }

  goToCalendar(eventsCalendar) {

    // calendario
    let variable: boolean;
    if (this.userRole == 1) {
      variable = false;
    } else if (this.userRole == 2) {
      variable = false;
    }
    var initialLocaleCode = 'es';
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth();
    let d = today.getDate();
    var calendarEl = document.getElementById('calendar');
    var calendar = new FullCalendar.Calendar(calendarEl, {
      locale: initialLocaleCode,
      defaultDate: today,
      editable: true,
      selectable: variable,
      header: {
        left: 'title',
        center: 'month,agendaWeek,agendaDay',
        right: 'prev,next,today'
      },
      eventLimit: true, // allow "more" link when too many events
      events: eventsCalendar,
      views: {
        month: {
          titleFormat: { month: 'long', year: 'numeric' }
        },
        agendaWeek: {
          titleFormat: { month: 'long', year: 'numeric', day: 'numeric' },
        },
        agendaDay: {
          titleFormat: { month: 'short', year: 'numeric', day: 'numeric' },
        },
        agenda: { eventLimit: 1 }
      },
      eventClick: function (info) {
        info.jsEvent.preventDefault();
        //console.log(info)
        window.open(info.event.url, '_blank');
      },
      select: function (info) {
        //console.log(this.events)
        swal.fire({
          title: '',
          html: ``,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
          },
          buttonsStyling: false
        }).then(function (result) { });
      }
    });
    calendar.render();

  }

  // funciones para cambiar entre contenedores
  getProject(projectCode) {
    this.loading = true;
    return this._projectService.getOne(projectCode).then(
      response => {
        if (response.result == true) {
          this.project = response.records[0];
          //console.log(this.project);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }
  getClient(clientCode) {
    this.loading = true;
    return this._clientService.getOne(clientCode).then(
      response => {
        if (response.result == true) {
          this.client = response.records[0];
          //console.log(this.client);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  getFiles(clientCode) {
    this.loading = true;
    return this._fileService.getAllByClient(clientCode).then(
      response => {
        if (response.result == true) {
          this.fileList = response.records;
          //console.log(this.fileList);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  getFoldersByFolder(projectCode, folderCode) {
    this.loading = true;
    return this._folderService.getAllByFolder(projectCode, folderCode).then(
      response => {
        if (response.result == true) {
          this.folderList = response.records;
          this.temp = this.folderList;
          //console.log(this.folderList);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  getFolder(folderCode) {
    this.loading = true;
    return this._folderService.getOne(folderCode).then(
      response => {
        if (response.result == true) {
          this.folderActual = response.records[0];
          //console.log(this.folderActual);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  // Carga de archivos
  getUploadType() {

    if (this.contentQR == false) {
      this.contentQR = true;
    } else {
      this.contentQR = false;
    }

  }

  getUploadQR(content) {
    //console.log("si llega");
    this.qrGenerado = true;

    const myId = uuid.v4();

    this.file.fileQR = myId;

    this._fileService.getQR(myId).subscribe(
      response => {
        //console.log(response);
        this.imageQR = response;
      },
      error => {
        console.log(error);
      }
    );

    this.modalService.open(content).result.then(
      result => {
        //this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

    //console.log(myId);
  }

  // funciones para upload de archivos
  addFile(e) {
    //console.log(e);
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;

  }

  convertBase64(file) {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  }

  async saveFile() {
    this.isDisabled = true;

    //console.log(this.state.files);
    const file = this.state.files.item(0);

    await this.convertBase64(file).then(
      (data: string) => {
        this.file.fileObject = data;
        // console.log(this.file.fileObject)/* return data*/;
      }
    );

    this.file.fileStatus = 1;
    this.file.fileName = this.state.fileNames;
    this.file.fileUser = this.userCode;
    let a = this.file.fileDate['year'];
    let m = this.file.fileDate['month'];
    let d = this.file.fileDate['day'];
    let fecha = a + '-' + m + '-' + d;

    this.file.fileDate = fecha;

    //console.log(this.folderDefault);
    this._fileService.insert(this.file).subscribe(
      async (response) => {
        if (response.result == true) {
          //console.log(response);

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Realizar la actualización de la tabla
          window.location.reload();

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );

  }

  update() {
    this.folder.folderStatus = 1;

    // console.log(this.folder);
    this._folderService.update(this.folder).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          //limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  deleteFunction($event) {
    $event.preventDefault();

    this.folder.folderName = undefined;
    this.folder.folderStatus = undefined;
    this.folder.folderDeleted = 1;

    //console.log(this.client);

    swal.fire({
      title: "Esta seguro",
      text: "El folder se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        //console.log(this.activeRow.folderProject);
        //this.getFoldersByProject(this.activeRow.folderProject);
        swal.fire({
          title: "Eliminado!",
          text: "El folder se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  cleanModel() {

    // this.message.messageTo = null;
    // this.message.messageSubject = null;
    // this.message.messageBody = null;

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
