import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { ClientService } from "src/app/services/client.service";
import { Client } from "src/app/models/client";
import { User } from "src/app/models/user";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.scss"],
  providers: [ClientService]
})
export class ClientComponent implements OnInit {
  loading;
  // variables para modelos
  public client: Client;
  public isEdit: boolean;
  public role: number;
  public isDisabled: boolean;

  // variables para mostrar u ocultar el contenedor
  public contentPrincipal: boolean;
  public contentViewClientI: boolean;
  public contentViewClientJ: boolean;
  public contentCrudClientI: boolean;
  public contentCrudClientJ: boolean;

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp = [];
  public clientList: Client[] = new Array();
  public rows: any = [
    {
      name: "Tiger Nixon",
      position: "System Architect",
      office: "Edinburgh",
      age: "61"
    },
    {
      name: "Garrett Winters",
      position: "Accountant",
      office: "Tokyo",
      age: "63"
    },
    {
      name: "Ashton Cox",
      position: "Junior Technical Author",
      office: "San Francisco",
      age: "66"
    },
    {
      name: "Cedric Kelly",
      position: "Senior Javascript Developer",
      office: "Edinburgh",
      age: "22"
    },
    { name: "Airi Satou", position: "Accountant", office: "Tokyo", age: "33" },
    {
      name: "Brielle Williamson",
      position: "Integration Specialist",
      office: "New York",
      age: "61"
    },
    {
      name: "Herrod Chandler",
      position: "Sales Assistant",
      office: "San Francisco",
      age: "59"
    },
    {
      name: "Rhona Davidson",
      position: "Integration Specialist",
      office: "Tokyo",
      age: "55"
    },
    {
      name: "Colleen Hurst",
      position: "Javascript Developer",
      office: "San Francisco",
      age: "39"
    },
    {
      name: "Sonya Frost",
      position: "Software Engineer",
      office: "Edinburgh",
      age: "23"
    },
    {
      name: "Jena Gaines",
      position: "Office Manager",
      office: "London",
      age: "30"
    },
    {
      name: "Quinn Flynn",
      position: "Support Lead",
      office: "Edinburgh",
      age: "22"
    },
    {
      name: "Charde Marshall",
      position: "Regional Director",
      office: "San Francisco",
      age: "36"
    },
    {
      name: "Haley Kennedy",
      position: "Senior Marketing Designer",
      office: "London",
      age: "43"
    },
    {
      name: "Tatyana Fitzpatrick",
      position: "Regional Director",
      office: "London",
      age: "19"
    },
    {
      name: "Michael Silva",
      position: "Marketing Designer",
      office: "London",
      age: "66"
    },
    {
      name: "Paul Byrd",
      position: "Chief Financial Officer (CFO)",
      office: "New York",
      age: "64"
    },
    {
      name: "Gloria Little",
      position: "Systems Administrator",
      office: "New York",
      age: "59"
    },
    {
      name: "Bradley Greer",
      position: "Software Engineer",
      office: "London",
      age: "41"
    },
    {
      name: "Dai Rios",
      position: "Personnel Lead",
      office: "Edinburgh",
      age: "35"
    },
    {
      name: "Jenette Caldwell",
      position: "Development Lead",
      office: "New York",
      age: "30"
    },
    {
      name: "Yuri Berry",
      position: "Chief Marketing Officer (CMO)",
      office: "New York",
      age: "40"
    },
    {
      name: "Caesar Vance",
      position: "Pre-Sales Support",
      office: "New York",
      age: "21"
    },
    {
      name: "Doris Wilder",
      position: "Sales Assistant",
      office: "Sidney",
      age: "23"
    },
    {
      name: "Angelica Ramos",
      position: "Chief Executive Officer (CEO)",
      office: "London",
      age: "47"
    },
    {
      name: "Gavin Joyce",
      position: "Developer",
      office: "Edinburgh",
      age: "42"
    },
    {
      name: "Jennifer Chang",
      position: "Regional Director",
      office: "Singapore",
      age: "28"
    },
    {
      name: "Brenden Wagner",
      position: "Software Engineer",
      office: "San Francisco",
      age: "28"
    },
    {
      name: "Fiona Green",
      position: "Chief Operating Officer (COO)",
      office: "San Francisco",
      age: "48"
    },
    {
      name: "Shou Itou",
      position: "Regional Marketing",
      office: "Tokyo",
      age: "20"
    },
    {
      name: "Michelle House",
      position: "Integration Specialist",
      office: "Sidney",
      age: "37"
    },
    { name: "Suki Burks", position: "Developer", office: "London", age: "53" },
    {
      name: "Prescott Bartlett",
      position: "Technical Author",
      office: "London",
      age: "27"
    },
    {
      name: "Gavin Cortez",
      position: "Team Leader",
      office: "San Francisco",
      age: "22"
    },
    {
      name: "Martena Mccray",
      position: "Post-Sales support",
      office: "Edinburgh",
      age: "46"
    },
    {
      name: "Unity Butler",
      position: "Marketing Designer",
      office: "San Francisco",
      age: "47"
    },
    {
      name: "Howard Hatfield",
      position: "Office Manager",
      office: "San Francisco",
      age: "51"
    },
    {
      name: "Hope Fuentes",
      position: "Secretary",
      office: "San Francisco",
      age: "41"
    },
    {
      name: "Vivian Harrell",
      position: "Financial Controller",
      office: "San Francisco",
      age: "62"
    },
    {
      name: "Timothy Mooney",
      position: "Office Manager",
      office: "London",
      age: "37"
    },
    {
      name: "Jackson Bradshaw",
      position: "Director",
      office: "New York",
      age: "65"
    },
    {
      name: "Olivia Liang",
      position: "Support Engineer",
      office: "Singapore",
      age: "64"
    }
  ];

  constructor(
    private _clientService: ClientService,
    private toastr: ToastrService,
    private router: Router,
    private _route: ActivatedRoute,
  ) {

    // Instancia de modelos
    this.client = new Client(
      undefined, undefined, undefined, undefined, undefined, 
      undefined, undefined, undefined, undefined, undefined, 
      undefined, undefined, undefined
      );

    // Visualizador de datatable
    this.temp = this.clientList.map((prop, key) => {
      return {
        ...prop,
        id: key
      };

    });

  }

  async ngOnInit() {
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }
    this.role = _user.userRole ? parseInt(_user.userRole.toString()) : 0;

    this.isDisabled = false;

    this._route.paramMap.subscribe(async (params: any) => {
      const clientType = params.params.clientType;

      if(clientType == 0){
        this.router.navigate(
          ['client']
        );
      }else if(clientType == 1){
        this.getViewClientI();
      }else if(clientType == 2){
        this.getViewClientJ();
      }

    });

    // Asignamos true al contenedor principal
    this.contentPrincipal = true;

    // Asignamos false a la variable de edición
    this.isEdit = false;

    // funciones iniciales

  }

  // funciones para cambiar de contenedor
  async getViewClientI() {
    await this.getClientsByType(1);
    this.contentPrincipal = false;
    this.contentViewClientI = true;
    this.contentViewClientJ = false;
    this.contentCrudClientI = false;
    this.contentCrudClientJ = false;
    this.isEdit = false;
    this.cleanModel();
  }

  getCrudClientI() {
    this.contentPrincipal = false;
    this.contentViewClientI = false;
    this.contentViewClientJ = false;
    this.contentCrudClientI = true;
    this.contentCrudClientJ = false;
  }

  async getViewClientJ() {
    await this.getClientsByType(2);
    this.contentPrincipal = false;
    this.contentViewClientI = false;
    this.contentViewClientJ = true;
    this.contentCrudClientI = false;
    this.contentCrudClientJ = false;
    this.isEdit = false;
    this.cleanModel();
  }

  getCrudClientJ() {
    this.contentPrincipal = false;
    this.contentViewClientI = false;
    this.contentViewClientJ = false;
    this.contentCrudClientI = false;
    this.contentCrudClientJ = true;
  }

  getClient() {
    this.contentPrincipal = true;
    this.contentViewClientI = false;
    this.contentViewClientJ = false;
    this.contentCrudClientI = false;
    this.contentCrudClientJ = false;
  }

  // funciones de datatable
  getClientsByType(type) {
    this.loading = true;
    return this._clientService.getAllByType(type).then(
      response => {
        if (response.result == true) {
          this.clientList = response.records;
          this.temp = this.clientList;
          //console.log(this.temp);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }
  
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.clientList.filter(function (d) {

      for (var key in d) {
        //console.log(d[key]);

        if(d[key] !== null){

          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }

        }

      }

      return false;
    });
  }

  onSelect($event) {
    console.log('Select Event', $event);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  getProjects($event) {
    $event.preventDefault();

    //console.log(this.activeRow.clientCode);

    this.router.navigate(
      ['project/' + this.activeRow.clientCode]
    );
  }

  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.client.clientCode = this.activeRow.clientCode;
    this.client.clientType = this.activeRow.clientType;
    this.client.clientCompany = this.activeRow.clientCompany;
    this.client.clientDocument = this.activeRow.clientDocument;
    this.client.clientName = this.activeRow.clientName;
    this.client.clientEmail = this.activeRow.clientEmail;
    this.client.clientPhone = this.activeRow.clientPhone;
    this.client.clientTaxDocument = this.activeRow.clientTaxDocument;
    this.client.clientAddress = this.activeRow.clientAddress;
    this.client.clientStatus = this.activeRow.clientStatus;


    if (this.client.clientType == 1) {
      this.getCrudClientI();

    } else {
      this.getCrudClientJ();
    }

  }

  deleteFunction($event) {
    $event.preventDefault();

    this.client.clientCode = this.activeRow.clientCode;
    this.client.clientType = this.activeRow.clientType;
    this.client.clientDeleted = 1;
    this.client.clientCompany = undefined;
    this.client.clientDocument = undefined;
    this.client.clientName = undefined;
    this.client.clientEmail = undefined;
    this.client.clientPhone = undefined;
    this.client.clientTaxDocument = undefined;
    this.client.clientAddress = undefined;
    this.client.clientStatus = undefined;

    //console.log(this.client);

    swal.fire({
      title: "Esta seguro",
      text: "El cliente se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update(this.client.clientType);
        swal.fire({
          title: "Eliminado!",
          text: "El cliente se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  // Funciones para manipulación de datos
  save(clientType) {
    this.isDisabled = true;
    
    this.client.clientType = clientType;
    this.client.clientStatus = 1;

    //console.log(this.client);
    this._clientService.insert(this.client).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          // Realizar la actualización de la tabla
          if (this.client.clientType == 1) {
            this.getViewClientI();

          } else {
            this.getViewClientJ();
          }

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);
        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          //console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  update(clientType) {
    this.isDisabled = true;

    // console.log(this.client);
    this._clientService.update(this.client).subscribe(
      async (response) => {
        if (response.result == true) {
          //console.log(response);

          // Realizar la actualización de la tabla
          if (clientType == 1) {
            this.getViewClientI();

          } else {
            this.getViewClientJ();
          }

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);
        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  cleanModel() {
    this.isDisabled = false;

    this.client.clientType = null;
    this.client.clientCompany = null;
    this.client.clientDocument = null;
    this.client.clientName = null;
    this.client.clientEmail = null;
    this.client.clientPhone = null;
    this.client.clientTaxDocument = null;
    this.client.clientAddress = null;
    this.client.clientStatus = null;

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
