import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-file",
  templateUrl: "./view-pdf.component.html",
  styleUrls: ["./view-pdf.component.scss"],
  providers: []
})

export class ViewPdfComponent implements OnInit {
  loading;
  // variables para mostrar u ocultar el contenedor
  public url: string;

  // variables que almacenan información de usuario y archivos

  // variables para data table

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {

    this.route.queryParamMap.subscribe((params: any) => {

      let fileKey = params.params.fileKey;
      fileKey = this.replaceAll(fileKey, ' ', '%20');
      this.url =  fileKey;

      //console.log(this.url);

    });


  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  }

}