export class Project{
	constructor(
        public projectCode: number,
		public projectBusinessCode: string,
        public projectUser: number,
        public projectClient: number,
        public projectName: string,
        public projectDescription: string,
        public projectStatus: number,
        public projectCreationDate: string,
        public projectDeleted: number
	){}
}