import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';
import swal from "sweetalert2";
import { User } from "src/app/models/user";

@Component({
  selector: "app-work-order",
  templateUrl: "./work-order.component.html",
  styleUrls: ["./work-order.component.scss"],
  providers: []
})

export class WorkOrderComponent implements OnInit {
  loading;
  
  // variables para menejo de información de usuario/clientes
  public userRole: number;
  public userCode: number;
  public clientCode: number;

  // variables para manejo de contenedores
  public contentPrincipal: boolean;
  public contentNewWO: boolean;

  constructor(
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private router: Router,
  ) {

  }

  async ngOnInit() {
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }

    this.userRole = _user.userRole ? parseInt(_user.userRole.toString()) : 0;
    this.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.clientCode = _user.userClient;

    // Ingreso a variables de información de proyecto
    this._route.paramMap.subscribe(async (params: any) => {
      const option = params.params.option;

      if (option !== undefined) {

        if(option == 0){
          this.router.navigate(
            ['work-order']
          );
        }

      }
    });

    this.contentPrincipal = true;

  }

  // funciones para cambiar entre contenedores
  getContentPrincipal() {
    this.contentPrincipal = true;
    this.contentNewWO = false;
  }

  getContentNewWO() {
    this.contentPrincipal = false;
    this.contentNewWO = true;
  }


  // Funciones para manipulación de datos
  save() {

    // this.folderDefault.folderStatus = 1;

    // //console.log(this.folderDefault);
    // this._folderService.insertFolder(this.folderDefault).subscribe(
    //   async (response) => {
    //     if (response.result == true) {
    //       // console.log(response);

    //       // limpiar el modelo
    //       this.cleanModel();

    //       // Mostrar notificación
    //       this.showNotification(1, response.message);

    //     } else {

    //       // limpiar el modelo
    //       this.cleanModel();

    //       // Mostrar notificación
    //       this.showNotification(3, response.errorMessage);
    //       // console.log(response);
    //     }
    //   },
    //   error => {
    //     // Mostrar notificacion
    //     this.showNotification(2, error);
    //     console.log(error);
    //   }
    // );

  }

  update() {
    // this.folder.folderStatus = 1;

    // // console.log(this.folder);
    // this._folderService.update(this.folder).subscribe(
    //   async (response) => {
    //     if (response.result == true) {
    //       // console.log(response);

    //       //limpiar el modelo
    //       this.cleanModel();

    //       // Mostrar notificación
    //       this.showNotification(1, response.message);

    //     } else {

    //       // limpiar el modelo
    //       this.cleanModel();

    //       // Mostrar notificación
    //       this.showNotification(3, response.errorMessage);
    //       // console.log(response);
    //     }
    //   },
    //   error => {
    //     // Mostrar notificacion
    //     this.showNotification(2, error);
    //     console.log(error);
    //   }
    // );
  }

  deleteFunction($event) {
    $event.preventDefault();

    // this.folder.folderName = undefined;
    // this.folder.folderStatus = undefined;
    // this.folder.folderDeleted = 1;

    // //console.log(this.client);

    swal.fire({
      title: "Esta seguro",
      text: "El folder se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        //console.log(this.activeRow.folderProject);
        //this.getFoldersByProject(this.activeRow.folderProject);
        swal.fire({
          title: "Eliminado!",
          text: "El folder se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  cleanModel() {

    // this.message.messageTo = null;
    // this.message.messageSubject = null;
    // this.message.messageBody = null;

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
