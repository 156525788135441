<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home&nbsp;</a>
        </div>
        <div *ngIf="contentPrincipal">
            / <a>OT</a>
        </div>
        <div *ngIf="contentNewWO">
            /<a routerLink="/work-order/0"> OT </a>/<a> Nueva OT</a>
        </div>
        <!-- <div *ngIf="contentViewClientI || contentCrudClientI">
            /<a routerLink="/client/0">Clientes</a>/<a>Individuales</a>
        </div>
        <div *ngIf="contentViewClientJ || contentCrudClientJ">
            /<a routerLink="/client/0">Clientes</a>/<a>Juridicos</a>
        </div> -->
    </div>
</div>
<!-- Contenedor principal -->
<div class="main-content" *ngIf="contentPrincipal">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <!-- <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getContentPrincipal();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div> -->
                        <div class="col-md-12">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getContentNewWO();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Crear orden de trabajo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Consulta de orden de trabajo</h4>
                    <h6 class="category">Puedes buscar una orden de trabajo escribiendo en la casilla "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Mostrar
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true" [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <!-- <a href="#" class="btn btn-round btn-success btn-icon btn-sm" (click)="viewPDF($event);"><i
                                            class="now-ui-icons arrows-1_cloud-download-93"></i></a> -->
                                    <a [href]="row.fileSignUrl" target="_blank" class="btn btn-round btn-success btn-icon btn-sm"><i
                                                class="now-ui-icons arrows-1_cloud-download-93"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm" *ngIf="userRole == 1" (click)="deleteFunction($event);"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedor nueva orden -->
<div class="main-content" *ngIf="contentNewWO">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getContentPrincipal();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                NIT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>