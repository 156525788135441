import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { User } from 'src/app/models/user';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ClientService]
})
export class HomeComponent implements OnInit {
  loading; 
  // Variables
  public client: Client;
  public user: User;
  public _cognitoCode;

  constructor(
    private clientService: ClientService,
  ) { 
    this.client = new Client(null, null,null,null,null,null,null,null,null,null,null,null,null);
    this.user = new User(null, null, null, null, null, null, null, null, null, null);
  }

  async ngOnInit() {
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }
    this._cognitoCode = _user.userCognitoCode ? _user.userCognitoCode.toString() : 0;

    await this.getOneClient(this._cognitoCode);
  }

  getOneClient(cognitoCode){
    
    this.loading = true;
    return this.clientService.getOne2(cognitoCode).then(
      response => {
        this.client = response.records[0];
        // console.log(this.client);
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

}
