
<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home&nbsp;</a>
        </div>
        <div *ngIf="contentPrincipal">
            /<a> Usuarios </a>
        </div>
        <div *ngIf="contentViewUserA || contentCrudUserA">
            /<a routerLink="/user/0"> Usuarios </a>/<a> Admin</a>
        </div>
        <div *ngIf="contentViewUserC || contentCrudUserC">
            /<a routerLink="/user/0"> Usuarios </a>/<a> Cliente</a>
        </div>
    </div>
</div>
<!-- Contenedor principal -->
<div class="main-content" *ngIf="contentPrincipal">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="statistics">
                                <a (click)="getViewUserA();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-user-circle fa-5x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Usuario Admin</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="statistics">
                                <a (click)="getViewUserC();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-user fa-4x"></i>
                                        </div>
                                        <h6 class="stats-title">Usuario cliente</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contenedor ver usuario admin -->
<div class="main-content" *ngIf="contentViewUserA">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getUser();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudUserA()">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar usuario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de usuarios administradores</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Mostrar
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">

                            <ngx-datatable-column name="Nombre" rowHeight="auto"></ngx-datatable-column>
                            <ngx-datatable-column name="Correo" rowHeight="auto"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado" rowHeight="auto"></ngx-datatable-column>
                            <ngx-datatable-column rowHeight="auto" [sortable]="true" [canAutoResize]="true" [draggable]="true" [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <!-- <a href="#" class="btn btn-round btn-info btn-icon btn-sm like" (click)="likeFunction($event)"><i class="fas fa-eye"></i></a> -->
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="editFunction($event)"><i class="fas fa-pencil-alt"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedor crud usuario admin -->
<div class="main-content" *ngIf="contentCrudUserA">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getViewUserA();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudUserA();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar usuario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title" *ngIf="!isEdit">Crear usuario</h4>
                    <h4 class="card-title" *ngIf="isEdit">Modificar usuario</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Nombre</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="user.userName" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>Correo</label>
                            <div class="form-group">
                                <input type="email" class="form-control" [(ngModel)]="user.userEmail" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button type="button" class="btn btn-success" (click)="update(1)" *ngIf="isEdit" [disabled]="isDisabled">Modificar</button>
                    <button type="button" class="btn btn-success" (click)="save(1)" *ngIf="!isEdit" [disabled]="isDisabled">Guardar</button>
                </div>
            </div>
            <!-- end content-->
        </div>
        <!--  end card  -->
    </div>
    <!-- end col-md-12 -->
</div>
<!-- Contenedor ver usuario cliente -->
<div class="main-content" *ngIf="contentViewUserC">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getUser();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudUserC();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar usuario
                                </button>
                            </div>
                            <ng-template #classic let-c="close" let-d="dismiss">
                                <div class="modal-header justify-content-center" *ngIf="isEdit">
                                    <button type="button" class="close" (click)="d('Cross click')">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title title-up">Modificar usuario</h4>
                                </div>
                                <div class="modal-header justify-content-center" *ngIf="!isEdit">
                                    <button type="button" class="close" (click)="d('Cross click')">
                                        <i class="now-ui-icons ui-1_simple-remove"></i>
                                    </button>
                                    <h4 class="title title-up">Agregar usuario</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Cliente</label>
                                            <angular2-multiselect [data]="clientList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                                            </angular2-multiselect>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Nombre</label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" [(ngModel)]="user.userName" />
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Correo</label>
                                            <div class="form-group">
                                                <input type="email" class="form-control" [(ngModel)]="user.userEmail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-success" (click)="update(2)" *ngIf="isEdit">Modificar</button>
                                    <button type="button" class="btn btn-success" (click)="save(2)" *ngIf="!isEdit">Guardar</button>
                                    <button type="button" class="btn btn-danger" (click)="c('Close click')">Cerrar</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de usuarios cliente</h4>
                    <h6 class="category">Puedes filtrar por cualquier campo en la caja de texto "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Show
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Cliente"></ngx-datatable-column>
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column name="Correo"></ngx-datatable-column>
                            <ngx-datatable-column name="Estado"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <!-- <a href="#" class="btn btn-round btn-info btn-icon btn-sm like" (click)="likeFunction($event)"><i class="fas fa-eye"></i></a> -->
                                    <a href="#" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="editFunction($event)"><i class="fas fa-pencil-alt"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedor crud usuario cliente -->
<div class="main-content" *ngIf="contentCrudUserC">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a (click)="getViewUserC();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="getCrudUserC();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Agregar usuario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title" *ngIf="!isEdit">Crear usuario</h4>
                    <h4 class="card-title" *ngIf="isEdit">Modificar usuario</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Cliente</label>
                            <angular2-multiselect [data]="clientList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                            </angular2-multiselect>
                        </div>
                        <div class="col-sm-6">
                            <label>Nombre</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="user.userName" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>Correo</label>
                            <div class="form-group">
                                <input type="email" class="form-control" [(ngModel)]="user.userEmail" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>Teléfono</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="user.userPhone" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button type="button" class="btn btn-success" (click)="update(2)" *ngIf="isEdit" [disabled]="isDisabled">Modificar</button>
                    <button type="button" class="btn btn-success" (click)="save(2)" *ngIf="!isEdit" [disabled]="isDisabled">Guardar</button>
                </div>
            </div>
            <!-- end content-->
        </div>
        <!--  end card  -->
    </div>
    <!-- end col-md-12 -->
</div>