import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Folder } from '../models/folder';

import { FolderDefault } from '../models/folder-default';
import { environment } from 'src/environments/environment';

@Injectable()
export class FolderService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
  }

  // Obtiene el listado de todos los folders
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers }).toPromise();
  }

  // Obtiene el listado de folder por proyecto
  getAllByProyect(projectCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-folder/project/' + projectCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de folder por folder
  getAllByFolder(projectCode, folderCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(
        this.url +
          'isocretosystem-folder/projectFolder/' +
          projectCode +
          '/' +
          folderCode,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  // Obtiene el listado de folders solo codigo y nombre
  getAllList(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-folder/default/', { headers: headers })
      .toPromise();
  }

  // Obtiene un solo folder a la vez
  getOne(folderCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-folder/one/' + folderCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Inserta un folder
  insert(folder: Folder): Observable<any> {
    let params = JSON.stringify(folder);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + '', params, { headers: headers });
  }

  // Actualiza un folder
  update(folder: Folder): Observable<any> {
    let params = JSON.stringify(folder);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url + 'isocretosystem-folder', params, {
      headers: headers,
    });
  }

  // Inserta un forlder en un proyecto
  insertFolder(folder: Folder): Observable<any> {
    let params = JSON.stringify(folder);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'isocretosystem-folder', params, {
      headers: headers,
    });
  }
}
