<footer class="footer">
    <div class="container-fluid">
        <nav>
            <ul>
                &copy; 2021, ISOCRETO. Todos los derechos reservados.
            </ul>
        </nav>
        <div class="copyright" id="copyright">
            Desarrollado por Ideas Multiplataforma Avanzadas
            <a href="https://www.ima.com.gt" target="_blank">IMA</a>.
        </div>
    </div>
</footer>