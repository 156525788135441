
<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="link-header ml-3">
        <a>Home</a>
    </div>
</div>
<br />
<div class="main-content">
    <div class="header text-center">
        <h4 class="title">Bienvenido {{client.clientCompany}} a su</h4>
        <h6 class="title">Sistema de gestión ISOCRETO</h6>
    </div>
    <div class="row">
        <div class="col-md-12"></div>
    </div>
</div>