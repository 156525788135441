import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { UserAutoConfirmComponent } from './pages/auth/user-auto-confirm/user-auto-confirm.component';
import { ClientComponent } from './pages/client/client.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FolderComponent } from './pages/folder/folder.component';
import { CalendarFolderComponent } from './pages/calendar-folder/calendar-folder.component';
import { FileComponent } from './pages/file/file.component';
import { HomeComponent } from './pages/home/home.component';
import { ProjectComponent } from './pages/project/project.component';
import { UserComponent } from './pages/user/user.component';
import { ViewPdfComponent } from './pages/view-pdf/view-pdf.component';
import { QrScannerComponent } from 'angular2-qrscanner';
import { ProfileComponent } from './pages/profile/profile.component';
import { WorkOrderComponent } from './pages/work-order/work-order.component';
import { QrValidateComponent } from './pages/qr-validate/qr-validate.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  // project
  { path: 'project', component: ProjectComponent },
  { path: 'project/:clientCode', component: ProjectComponent },
  { path: 'project/:clientCode/:viewCode', component: ProjectComponent },
  // folder
  { path: 'folder/:projectCode', component: FolderComponent },
  { path: 'folder/:projectCode/:folderCode/:level', component: FolderComponent },
  // Calendar folder
  { path: 'calendar-folder/:projectCode/:folderCode/:level', component: CalendarFolderComponent },
  // file
  { path: 'file/:folderCode', component: FileComponent },
  // view-pdf
  { path: 'view-pdf', component: ViewPdfComponent },
  // qr-validate
  { path: 'qr-validate', component: QrValidateComponent },
  // contact
  { path: 'contact', component: ContactComponent },
  // user
  { path: 'user', component: UserComponent },
  { path: 'user/:userType', component: UserComponent },
  { path: 'userAutoConfirm/:userEmail/:verificationCode', component: UserAutoConfirmComponent },
  // contact
  { path: 'client', component: ClientComponent },
  { path: 'client/:clientType', component: ClientComponent },
  // Perfil
  { path: 'profile', component: ProfileComponent },
  // Orden de trabajo
  { path: 'work-order', component: WorkOrderComponent },
  { path: 'work-order/:option', component: WorkOrderComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
