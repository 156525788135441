export class Message{
	constructor(
		public messageCode: number,
        public messageFrom: number,
        public messageTo: number,
        public messageSubject: string,
        public messageBody: string,
        public messageOutstanding: number,
        public messageSendDate: string,
        public messageReadDate: string,
        public messageResponseCode: number,
        public messageStatus: number,
        public messageAnswer: string
	){}
}