<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home</a> / <a>Perfil</a>
        </div>
    </div>
</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h5 class="title">Editar perfil (cambio de contraseña)</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Usuario</label>
                                <input type="text" class="form-control" [(ngModel)]="user.userEmail" disabled />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nombre</label>
                                <input type="text" class="form-control" [(ngModel)]="user.userName" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label>Contraseña Actual</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <a (click)="changeText()">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                                <input type="password" id="pass" class="form-control" [(ngModel)]="password.previousPassword" placeholder="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Contraseña Nueva</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <a (click)="changeTextTwo()">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                                <input type="password" id="passTwo" class="form-control" [(ngModel)]="password.proposedPassword" placement="bottom" ngbTooltip="Política de contraseña: 8 caracteres mínimo, 1 Letra mayúscula al menos, 1 letra minúscula al menos, 1 caracter especial al menos, 1 número al menos"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" card-footer ">
                    <button type="button " class="btn btn-success " (click)="changePassword(); " [disabled]="isDisabled ">Modificar</button>
                </div>
            </div>
        </div>
    </div>
</div>