<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home&nbsp;</a>
        </div>
        <div *ngIf="client.clientType == 1">
            /<a routerLink="/client/0"> Clientes </a>/<a routerLink="/client/1"> Individuales&nbsp;</a>
        </div>
        <div *ngIf="client.clientType == 2">
            /<a routerLink="/client/0"> Clientes </a>/<a routerLink="/client/2"> Juridicos&nbsp;</a>
        </div>
        <div *ngIf="contentPrincipal">
            /<a> Proyectos </a>
        </div>
        <div *ngIf="contentCrudProject">
            /<a routerLink="/project/{{project.projectClient}}/0"> Proyectos </a>/<a> Crear-Editar</a>
        </div>
    </div>
</div>
<!-- Contenedor inicial de proyectos -->
<div class="main-content" *ngIf="contentPrincipal">
    <div class="row" *ngIf="userRole === 1">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a *ngIf="client.clientType == 1" routerLink="/client/1">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                                <a *ngIf="client.clientType == 2" routerLink="/client/2">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="goToContentCrudProject();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Crear proyecto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{client.clientCompany}}</h4>
                    <h6 class="category">Puedes buscar proyecto utilizar la casilla "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Mostrar
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">
                            <!-- <ngx-datatable-column name="No."></ngx-datatable-column> -->
                            <ngx-datatable-column name="Proyecto"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                    Acciones
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a href="#" class="btn btn-round btn-info btn-icon btn-sm like" (click)="getFolders($event);"><i class="fas fa-eye"></i></a>
                                    <a href="#" *ngIf="userRole == 1" class="btn btn-round btn-success btn-icon btn-sm edit" (click)="editFunction($event);"><i class="fas fa-pencil-alt"></i></a>
                                    <a href="#" *ngIf="userRole == 1" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event);"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>
<!-- Contenedro crud de proyectos -->
<div class="main-content" *ngIf="contentCrudProject">
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a routerLink="/project/{{project.projectClient}}/0">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="statistics">
                                <button class="btn btn-default btn-block btn-lg mt-10" (click)="goToContentCrudProject();">
                                    <span class="btn-label">
                                        <i class="now-ui-icons ui-1_simple-add"></i>
                                    </span>
                                    &nbsp; Crear proyecto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title" *ngIf="!isEdit">Crear proyecto</h4>
                    <h4 class="card-title" *ngIf="isEdit">Modificar proyecto</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Nombre de proyecto</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="project.projectName" />
                            </div>
                        </div>
                        <div class="col-md-8">
                            <label>Descripción de proyecto</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="project.projectDescription" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button type="button" class="btn btn-success" *ngIf="isEdit" (click)="update();" [disabled]="isDisabled">Modificar</button>
                    <button type="button" class="btn btn-success" *ngIf="!isEdit" (click)="save();" [disabled]="isDisabled">Guardar</button>
                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
</div>