<div class="main-content">
    <div class="container">
        <div class="col-md-4 ml-auto mr-auto">
            <div class="form">
                <div class="card card-login card-plain">
                    <div class="card-header ">
                        <div class="logo-container">
                            <img src="assets/img/login.png" />
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class="input-group no-border form-control-lg">
                            <span class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="now-ui-icons users_circle-08"></i>
                                </div>
                            </span>
                            <input type="text" class="form-control" placeholder="Usuario" [(ngModel)]="user.userEmail" />
                        </div>
                        <div class="input-group no-border form-control-lg">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <a (click)="changeText()">
                                        <i class="now-ui-icons objects_key-25"></i>
                                    </a>
                                </div>
                            </div>
                            <input type="password" id="pass" placeholder="Contraseña" class="form-control" [(ngModel)]="user.userPassword" />
                        </div>
                    </div>
                    <div class="card-footer ">
                        <button class="btn btn-primary btn-round btn-lg btn-block mb-3" (click)="validate()">Ingresar</button>
                        <!-- <div class="pull-right">
                            <h6><a href="#pablo" class="link footer-link">Olvido su contraseña?</a></h6>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>