import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Password } from '../../models/password';
import { UserService } from '../../services/user.service';
import { PathLocationStrategy } from '@angular/common';
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    providers:[UserService]
})
export class ProfileComponent implements OnInit {
  loading;
    // variables iniciales
    public user: User;
    public password: Password;
    public isDisabled: boolean;
    public show: boolean;
    public showTwo: boolean;

    constructor(
        private userService: UserService,
        private toastr: ToastrService,
    ) {
        this.user = new User(null, null, null, null, null, null, null, null, null, null);
        this.password =  new Password(null, null, null);
    }

    ngOnInit() {
        // retorno de información del local storage
        let _user = {} as User;
        if (localStorage.getItem('user')) {
            _user = JSON.parse(localStorage.getItem('user'));
        }

        // variables para mostrar
        this.user.userEmail = _user.userRole ? _user.userEmail : null;
        this.user.userName = _user.userRole ? _user.userName : null;
        this.user.userCognitoCode = _user.userCognitoCode ? _user.userCognitoCode :  null;
        this.isDisabled = false;
    }

    changeText(){
      let elemento :any = document.getElementById('pass');

      if (this.show) {
        this.show = false;
        elemento.type = "password";
      }
      else {
        this.show = true;
        elemento.type = "text";
      }
    }

    changeTextTwo(){
      let elemento :any = document.getElementById('passTwo');

      if (this.showTwo) {
        this.showTwo = false;
        elemento.type = "password";
      }
      else {
        this.showTwo = true;
        elemento.type = "text";
      }
    }

    changePassword() {
        //console.log("Cambio de contraseña");
        this.isDisabled = true;


        return this.userService.updatePassword(
            this.user.userCognitoCode, 
            this.password.previousPassword, 
            this.password.proposedPassword).then(
            response => {

                if(response.result == true){
                    this.showNotification(1, response.message);
                    this.isDisabled = false;
                }else{
                    this.showNotification(2, response.message);
                    this.isDisabled = false;
                }

                //console.log(response);
                
            }, error => {
                //console.log(error);
                this.showNotification(3, error);
                this.isDisabled = false;
            }
        );

    }

      // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
