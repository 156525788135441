import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { QrScannerComponent } from 'angular2-qrscanner';
import { File } from 'src/app/models/file';
import { FileService } from '../../services/file.service';
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-qr-validate",
  templateUrl: "./qr-validate.component.html",
  styleUrls: ["./qr-validate.component.css"],
  providers: [FileService],
  encapsulation: ViewEncapsulation.None,
})
export class QrValidateComponent implements OnInit, AfterViewInit {

  @ViewChild(QrScannerComponent, { static: false }) qrScannerComponent: QrScannerComponent;

  // variables
  public file: File;
  public QRDetected: boolean;
  public videoDevices: MediaDeviceInfo[] = [];

  // variables para select
  public listDevices: any[] = new Array();
  public selectedItems = [];
  public dropdownSettings = {};

  constructor(
    private fileService: FileService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
  ) {
    this.file = new File(null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  ngOnInit() {
    this.QRDetected = false;
  }

  ngAfterViewInit() {

    this.qrScannerComponent.getMediaDevices().then(devices => {
      //console.log(devices);

      for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
          this.videoDevices.push(device);
        }
      }

      // recorrer la lista para transformarla en el formato del select
      this.videoDevices.forEach(vD => {

        const id = vD.deviceId;
        const itemName = vD.label;

        this.listDevices.push({
          id: id,
          itemName: itemName,
        });

      });

      // console.log(this.listDevices);

    });

    this.qrScannerComponent.capturedQr.subscribe(result => {
      /* Aqui se debe colocar que se hará cuando exista respuesta de la lectura */
      //console.log(result);

      this.QRDetected = true;

      var indices = [];

      for (var i = 0; i < result.length; i++) {
        if (result[i].toLowerCase() === "/") {
          indices.push(i);
          result = result.replace("/", "0");
        }
      }

      this.getFileByQR(result);

    });

  }

  // funciones para select
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  getSelectSettings(selected) {

    // console.log(selected);

    this.selectedItems = [selected];
    this.dropdownSettings = {
      singleSelection: true,
      multipleSelection: false,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };
  }

  onItemSelect(item: any) {

    // console.log(item.id);

    const videoDevicesIndex = this.videoDevices.findIndex(x => x.deviceId == item.id);

    //console.log(videoDevicesIndex);

    if (this.videoDevices.length > 0) {
      // let choosenDev;
      for (const dev of this.videoDevices) {
        this.qrScannerComponent.chooseCamera.next(this.videoDevices[videoDevicesIndex]);
      }
    }
  }

  getFileByQR(QRCode) {
    this.fileService.getOneByQR(QRCode).subscribe(
      response => {
        this.file = response.records[0];

        //console.log(this.file.fileKey);

        if (response.result == true) {
          this.showNotification(1, response.message);
          window.open( this.file.fileSignUrl, '_blank');
        } else {
          this.showNotification(2, response.message);
        }

      },
      error => {
        //console.log(error);
        this.showNotification(3, error);
      });
  }

  newScann() {
    //console.log("si");
    window.location.reload();
  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
