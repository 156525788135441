import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router';
import swal from "sweetalert2";
import { FolderService } from "src/app/services/folder.service";
import { Folder } from "src/app/models/folder";
import { User } from "src/app/models/user";
import { Project } from "src/app/models/project";
import { ProjectService } from "src/app/services/project.service";
import { Client } from "src/app/models/client";
import { ClientService } from "src/app/services/client.service";
import { FileService } from "src/app/services/file.service";
import { File } from "src/app/models/file";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from '../../../environments/environment';

const uuid = require('uuid');

@Component({
  selector: "app-folder",
  templateUrl: "./folder.component.html",
  styleUrls: ["./folder.component.scss"],
  providers: [FolderService, ProjectService, ClientService, FileService]
})

export class FolderComponent implements OnInit {
  loading;
  // variables para mostrar u ocultar el contenedor
  public userRole: number;
  public userCode: number;
  public folder: Folder;
  public folderActual: Folder;
  public clientCode: number;
  public project: Project;
  public client: Client;
  public isDisabled: boolean;
  public contentQR: boolean;
  public qrGenerado: boolean;
  public imageQR: string;
  public carpetaCalendario: boolean;
  public state: any = {};
  public level: number;

  // variables que almacenan información de usuario y folders
  public file: File;
  public folderCode;
  public projectCode;

  // variables para select
  public folderList: Folder[] = new Array();
  public selectedItems = [];
  public dropdownSettings = {};

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;

  constructor(
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private router: Router,
    private _route: ActivatedRoute,
    private _folderService: FolderService,
    private _projectService: ProjectService,
    private _clientService: ClientService,
    private _fileService: FileService,
    private modalService: NgbModal,
  ) {
    this.folder = new Folder(null, null, null, null, 0, null, 1, null, null, null, null);
    this.folderActual = new Folder(null, null, null, null, null, null, null, null, null, null, null);
    this.project = new Project(null, null, null, null, null, null, null, null, null);
    this.client = new Client(null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.file = new File(null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  async ngOnInit() {
    // inicializa el contenedor principal
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }

    this.userRole = _user.userRole ? parseInt(_user.userRole.toString()) : 0;
    this.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;
    this.clientCode = _user.userClient;
    this.isDisabled = false;
    this.contentQR = false;
    this.qrGenerado = false;
    this.carpetaCalendario = false;

    // Validamos en la ruta el codigo de proyecto y tambien el folder a validar
    this._route.paramMap.subscribe(async (params: any) => {
      this.projectCode = params.params.projectCode;
      this.folderCode = params.params.folderCode;
      this.level = params.params.level == undefined ? 0 : params.params.level;

      if (this.projectCode !== undefined) {

        this.folder.folderProject = this.projectCode;
        this.file.fileProject = this.projectCode;
        this.file.fileParent = this.projectCode;
        this.folder.folderLevel = 0;
        this.file.fileLevel = 0;
        this.folder.folderParent = this.projectCode;
        await this.getProject(this.projectCode);
        await this.getClient(this.project.projectClient);

        if (this.folderCode !== undefined) {

          // console.log(projectCode, folderCode);
          await this.getFolder(this.folderCode);
          this.getFoldersByFolder(this.projectCode, this.folderCode);
          this.folder.folderParent = this.folderCode;
          this.file.fileParent = this.folderCode;
          this.level = parseInt(this.level.toString()) + 1;
          this.file.fileLevel = this.level
          this.folder.folderLevel = this.level;
        } else {

          this.getFoldersByProject(this.projectCode);
        }

      }

      //console.log(this.level);

    });

    // funciones iniciales

  }

  // funciones para cambiar entre contenedores
  getProject(projectCode) {
    this.loading = true;
    return this._projectService.getOne(projectCode).then(
      response => {
        if (response.result == true) {
          this.project = response.records[0];
          //console.log(this.project);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getClient(clientCode) {
    this.loading = true;
    return this._clientService.getOne(clientCode).then(
      response => {
        if (response.result == true) {
          this.client = response.records[0];
          //console.log(this.client);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getFolder(folderCode) {
    this.loading = true;
    return this._folderService.getOne(folderCode).then(
      response => {
        if (response.result == true) {
          this.folderActual = response.records[0];
          //console.log(response);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getNext($event) {
    $event.preventDefault();

    //console.log(this.activeRow.projectCode);

    this.router.navigate(
      ['folder/' + this.folder.folderProject + '/' + this.activeRow.folderCode + '/' + this.activeRow.level]
    );
  }

  goToCalendar($event) {
    $event.preventDefault();

    //console.log(this.activeRow.projectCode);

    this.router.navigate(
      ['calendar-folder/' + this.folder.folderProject + '/' + this.activeRow.folderCode + '/' + this.activeRow.level]
    );
  }

  getCalendarFolder() {

    if (this.carpetaCalendario == false) {
      this.carpetaCalendario = true;
      this.folder.folderType = 1;
    } else {
      this.carpetaCalendario = false;
      this.folder.folderType = 0;
    }

  }

  // funciones para datatable
  getFoldersByProject(projectCode) {
    this.loading = true;
    this._folderService.getAllByProyect(projectCode).then(
      response => {
        if (response.result == true) {
          this.folderList = response.records;
          this.temp = this.folderList;
          //console.log(this.folderList);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  getFoldersByFolder(projectCode, folderCode) {
    this.loading = true;
    this._folderService.getAllByFolder(projectCode, folderCode).then(
      response => {
        if (response.result == true) {
          this.folderList = response.records;
          this.temp = this.folderList;
          //console.log(this.folderList);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.folderList.filter(function (d) {

      for (var key in d) {
        //console.log(d[key]);

        if (d[key] !== null) {

          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }

        }

      }
      return false;
    });
  }

  getProjects($event) {
    $event.preventDefault();

    this.router.navigate(
      ['operative/folder/' + this.activeRow.clientCode]
    );
  }

  onSelect($event) {
    console.log('Select Event', $event);
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  // funciones para upload de archivos
  addFile(e) {
    //console.log(e);
    let fileNames = e.target.files[0].name;
    let files = e.target.files;

    this.state.fileNames = fileNames;
    this.state.files = files;

  }

  getUploadType() {

    if (this.contentQR == false) {
      this.contentQR = true;
    } else {
      this.contentQR = false;
    }

  }

  getUploadQR(content) {

    this.qrGenerado = true;

    const myId = uuid.v4();
    this.file.fileQR = myId;

    this._fileService.getQR(myId).subscribe(
      response => {
        //console.log(response);
        this.imageQR = response;
      },
      error => {
        console.log(error);
      }
    );

    this.modalService.open(content).result.then(
      result => {
        //this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

    //console.log(myId);
  }

  convertBase64(file) {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  }

  async saveFile() {
    this.isDisabled = true;
    this.loading = true;

    //console.log(this.state.files);
    const fileData = this.state.files.item(0);
    console.log(JSON.stringify(this.state.files.item(0)));

    // await this.convertBase64(file).then(
    //   (data: string) => {
    //     this.file.fileObject = data;
    //     // console.log(this.file.fileObject)/* return data*/;
    //   }
    // );

    this.file.fileStatus = 1;
    this.file.fileName = this.state.fileNames;
    this.file.fileUser = this.userCode;
    //console.log(this.file)

    //console.log(this.folderDefault);
    this._fileService.insert(this.file).subscribe(
      async (response) => {
        if (response.result == true) {

          this._fileService.updateV2(response.signedUrl, fileData).then(
            response => {

                // limpiar el modelo
                this.cleanModel();

                // Mostrar notificación
                this.showNotification(1, "Se ha cargado el archivo correctamente");

                // Realizar la actualización de la tabla
                window.location.reload();

            },
            error => {
              this.showNotification(2, error);
              console.log(error);
            }
          )

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );

    this.loading = false;
  }

  // Funciones para manipulación de datos
  save() {
    this.isDisabled = true;

    //console.log(this.folderDefault);
    this._folderService.insertFolder(this.folder).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Realizar la actualización de la tabla
          window.location.reload();

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );

  }

  update() {
    this.isDisabled = true;

    this.folder.folderStatus = 1;

    // console.log(this.folder);
    this._folderService.update(this.folder).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          //limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Realizar la actualización de la tabla
          window.location.reload();

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  updateFile() {
    this.isDisabled = true;

    // this.folder.folderStatus = 1;
    // console.log(this.folder);
    this._fileService.update(this.file).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          //limpiar el modelo
          this.cleanModel();

          // Realizar la actualización de la tabla
          window.location.reload();

          // Mostrar notificación
          this.showNotification(1, response.message);

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  deleteFunction($event) {
    $event.preventDefault();

    this.folder.folderCode = this.activeRow.folderCode;
    this.folder.folderProject = this.activeRow.folderProject;
    this.folder.folderName = undefined;
    this.folder.folderStatus = undefined;
    this.folder.nombre = undefined;
    this.folder.fileDateOnly = undefined;
    this.folder.fileKey = undefined;
    this.folder.folderDeleted = 1;

    //console.log(this.folder);

    swal.fire({
      title: "Esta seguro",
      text: "El folder se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        //console.log(this.activeRow.folderProject);

        swal.fire({
          title: "Eliminado!",
          text: "El folder se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  deleteFunctionFile($event) {
    $event.preventDefault();

    this.file.fileCode = this.activeRow.folderCode;
    // this.file.fileFolder = this.activeRow.fileFolder;
    this.file.fileName = undefined;
    this.file.fileExtension = undefined;
    this.file.fileUser = undefined;
    this.file.fileKey = undefined;
    this.file.fileQR = undefined;
    this.file.fileStatus = undefined;
    this.file.fileDeleted = 1;
    this.file.fileObject = undefined;
    this.file.fileDate = undefined;

    //console.log(this.activeRow);

    swal.fire({
      title: "Esta seguro",
      text: "El Archivo se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.updateFile();
        //console.log(this.activeRow.folderProject);

        swal.fire({
          title: "Eliminado!",
          text: "El Archivo se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  cleanModel() {
    this.isDisabled = false;

    this.folder.folderName = null;
    // this.message.messageSubject = null;
    // this.message.messageBody = null;

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
