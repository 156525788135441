import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from '@angular/router';
import { User } from "src/app/models/user";
import { ClientService } from "src/app/services/client.service";
import { UserService } from "src/app/services/user.service";
import swal from "sweetalert2";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  providers: [UserService, ClientService]
})
export class UserComponent implements OnInit {
  loading;

  // variables para modelos
  public user: User;
  public isEdit: boolean;
  public role: number;
  public isDisabled: boolean;

  // variables para mostrar u ocultar el contenedor
  public contentPrincipal: boolean;
  public contentCrudUserA: boolean;
  public contentCrudUserC: boolean;
  public contentViewUserC: boolean;
  public contentViewUserA: boolean;

  // variables para select
  public clientList: any[];
  public selectedItems = [];
  public dropdownSettings = {};

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;
  public userList: User[] = new Array();

  constructor(
    private cdref: ChangeDetectorRef,
    private _userService: UserService,
    private _clientService: ClientService,
    private toastr: ToastrService,
    private router: Router,
    private _route: ActivatedRoute,
  ) {

    // Instancia de modelos
    this.user = new User(null, null, null, null, null, null, null, null, null, null);

  }

  async ngOnInit() {
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }
    this.role = _user.userRole ? parseInt(_user.userRole.toString()) : 0;
    this.user.userCognitoCode = _user.userCognitoCode ? _user.userCognitoCode : null;

    this.isDisabled = false;

    this._route.paramMap.subscribe(async (params: any) => {
      const userType = params.params.userType;

      if (userType == 0) {
        this.router.navigate(
          ['user']
        );
      } else if (userType == 1) {
        this.getViewUserA();
      } else if (userType == 2) {
        this.getViewUserC();
      }

    });

    // Asignamos true al contenedor principal
    this.contentPrincipal = true;

    // Asignamos false a la variable de edición
    this.isEdit = false;

    // Select
    this.getClientList();
    this.getSelectSettings(this.selectedItems);

  }

  // funciones para cambiar de contenedor
  async getViewUserA() {
    await this.getUsersByRole(1);
    this.contentViewUserA = true;
    this.contentViewUserC = false;
    this.contentCrudUserA = false;
    this.contentCrudUserC = false;
    this.contentPrincipal = false;
    this.isEdit = false;
    this.cleanModel();
  }

  getCrudUserA() {
    this.contentPrincipal = false;
    this.contentViewUserA = false;
    this.contentViewUserC = false;
    this.contentCrudUserA = true;
    this.contentCrudUserC = false;
  }

  async getViewUserC() {
    await this.getUsersByRole(2);
    this.contentPrincipal = false;
    this.contentViewUserA = false;
    this.contentViewUserC = true;
    this.contentCrudUserA = false;
    this.contentCrudUserC = false;
    this.isEdit = false;
    this.cleanModel();
  }

  getCrudUserC() {
    this.contentPrincipal = false;
    this.contentViewUserA = false;
    this.contentViewUserC = false;
    this.contentCrudUserA = false;
    this.contentCrudUserC = true;
  }

  getUser() {
    this.isEdit = false;
    this.contentPrincipal = true;
    this.contentViewUserA = false;
    this.contentViewUserC = false;
    this.contentCrudUserA = false;
    this.contentCrudUserC = false;
  }

  // funciones para select
  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  getClientList() {
    this._clientService.getAllList().subscribe(
      response => {
        if (response.result == true) {
          this.clientList = response.records;
          //console.log(this.clientList);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getSelectSettings(selected){

  // console.log(selected);

    this.selectedItems = [selected];
    this.dropdownSettings = {
      singleSelection: true,
      text: "Seleccione...",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "",
      lazyLoading: true
    };
  }

  onItemSelect(item: any) {

    this.user.userClient = item.id;
    // console.log(item.id);
    // console.log(this.selectedItems);
  }

  // funciones de datatable
  getUsersByRole(role) {
    this.loading = true;
    return this._userService.getAllByRole(role).then(
      response => {
        if (response.result == true) {
          this.userList = response.records;
          this.temp = this.userList;
          //console.log(response);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }
  
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.userList.filter(function (d) {

      for (var key in d) {
        //console.log(d[key]);

        if(d[key] !== null){

          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }

        }

      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  likeFunction($event) {
    $event.preventDefault();
    let details = "You've clicked LIKE button on \n{\n";
    for (var key in this.activeRow) {
      details += key + ": " + this.activeRow[key] + "\n";
    }
    details += "}.";
    alert(details);
  }

  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    console.log(this.activeRow);

    this.user.userCode = this.activeRow.userCode;
    this.user.userClient = this.activeRow.userClient;
    this.user.userRole = this.activeRow.userRole;
    this.user.userName = this.activeRow.userName;
    this.user.userEmail = this.activeRow.userEmail;
    this.user.userStatus = this.activeRow.userStatus;
    this.user.userPhone = this.activeRow.userPhone;

    const _selecteItem = this.clientList.find(x => x.id == this.activeRow.userClient);

    //console.log(_userCode);

    this.getSelectSettings(_selecteItem);

    if (this.user.userRole == 1) {
      this.getCrudUserA();

    } else {
      this.getCrudUserC();
    }

  }

  deleteFunction($event) {
    $event.preventDefault();

    this.user.userCode = this.activeRow.userCode;
    this.user.userRole = this.activeRow.userRole;
    this.user.userDeleted = 1;
    this.user.userName = this.activeRow.userName;
    this.user.userEmail = undefined;
    this.user.userClient = undefined;
    this.user.userCognitoCode = this.activeRow.userCognitoCode;
    this.user.userStatus = undefined;

    //console.log(this.user);

    swal.fire({
      title: "Esta seguro",
      text: "El usuario se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update(this.user.userRole);
        swal.fire({
          title: "Eliminado!",
          text: "El usuario se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  // Funciones para manipulación de datos
  save(userRole) {
    this.isDisabled = true;

    this.user.userRole = userRole;
    this.user.userStatus = 1;

    if (userRole == 1) {
      this.user.userClient = 1;
    }

    //console.log(this.user);
    this._userService.insert(this.user).subscribe(
      async (response) => {
        if (response.result == true) {
          //console.log(response);

          // Validamos que rol tiene para enviarlo a la vista correcta
          if (userRole == 1) {
            this.getViewUserA();

          } else {
            this.getViewUserC();
          }

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);
        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          //console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  update(userRole) {
    this.isDisabled = true;

    //console.log(this.user);
    this._userService.update(this.user).subscribe(
      async (response) => {
        if (response.result == true) {
          //console.log(response);

          // Validamos que rol tiene para enviarlo a la vista correcta
          if (userRole == 1) {
            this.getViewUserA();

          } else {
            this.getViewUserC();
          }

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(1, response.message);
        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          //console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  cleanModel() {
    this.isDisabled = false;

    this.user.userName = null;
    this.user.userEmail = null;
    this.user.userRole = null;
    this.user.userPhone = null;
    this.selectedItems = [];

  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }

}
