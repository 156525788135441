import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  focus;
  focus2;

  public user: User;
  public show: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.user = new User(null, null, null, null, null, null, null, null, null, null);
  }

  ngOnInit() {

    this.show = false;
    var $page = document.getElementsByClassName("full-page")[0];
    var image_src;
    var image_container = document.createElement("div");
    image_container.classList.add("full-page-background");
    image_container.style.backgroundImage = "url(assets/img/bg14.jpg)";
    $page.appendChild(image_container);
    $page.classList.add("login-page");

  }

  ngOnDestroy() {

    var $page = document.getElementsByClassName("full-page")[0];
    $page.classList.remove("login-page");

  }

  validate() {
    this.userService.signIn(this.user.userEmail, this.user.userPassword);
  }

  changeText() {
    // console.log("click");

    let elemento :any = document.getElementById('pass');

    if (this.show) {
      this.show = false;
      elemento.type = "password";
    }
    else {
      this.show = true;
      elemento.type = "text";
    } 

  }
  
}
