<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home&nbsp;</a>
        </div>
        <div *ngIf="userRole == 1">
            <div *ngIf="client.clientType == 1">
                /<a routerLink="/client/0"> Clientes </a>/<a routerLink="/client/1"> Individuales&nbsp;</a>
            </div>
            <div *ngIf="client.clientType == 2">
                /<a routerLink="/client/0"> Clientes </a>/<a routerLink="/client/2"> Juridicos&nbsp;</a>
            </div>
        </div>
        <div>
            /<a routerLink="/project/{{project.projectClient}}"> Proyectos &nbsp;</a>
        </div>
        <div *ngIf="!folderCode">
            / <a> {{project.projectName}} </a>
        </div>
        <div *ngIf="folderCode">
            / <a routerLink="/folder/{{project.projectCode}}"> {{project.projectName}} </a> / <a> {{folderActual.folderName}} </a>
        </div>
    </div>
</div>
<!-- Contenedor inicial de Folders -->
<div class="main-content">
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row" *ngIf="userRole == 1">
                        <!-- <div class="col-md-1">
                            <div class="statistics">
                                <a routerLink="/project/{{project.projectClient}}">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div> -->
                        <div class="col-md-6">
                            <div class="statistics">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h6 class="stats-title">Carpeta calendario</h6>
                                    </div>
                                    <div class="col-sm-2">
                                        <bSwitch [switch-on-color]="'primary'" (click)="getCalendarFolder();" switch-on-text="<i class='now-ui-icons ui-1_check'></i>" switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                        </bSwitch>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="folder.folderName" />
                                    </div>
                                    <div class="col-sm-2">
                                        <button class="btn btn-success btn-round mt-0" (click)="save();" [disabled]="isDisabled">Añadir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="statistics">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h6 class="stats-title">Cargar archivo con QR</h6>
                                    </div>
                                    <div class="col-sm-2">
                                        <bSwitch [switch-on-color]="'primary'" (click)="getUploadType();" switch-on-text="<i class='now-ui-icons ui-1_check'></i>" switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                        </bSwitch>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-9" *ngIf="!contentQR">
                                        <input type="file" (change)="addFile($event)" #input accept="application/pdf" />
                                    </div>
                                    <div class="col-sm-9" *ngIf="contentQR && !qrGenerado">
                                        <button class="btn btn-default btn-round mt-0" (click)="getUploadQR(classic);">Generar QR</button>
                                    </div>
                                    <div class="col-sm-9" *ngIf="contentQR && qrGenerado">
                                        <input type="file" (change)="addFile($event)" #input accept="application/pdf" />
                                    </div>
                                    <div class="col-sm-2">
                                        <button class="btn btn-success btn-round mt-0" (click)="saveFile();" [disabled]="isDisabled">Cargar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row" *ngIf="userRole == 2">
                        <div class="col-md-12">
                            <div class="statistics">
                                <a (click)="goToCalendar();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="now-ui-icons ui-1_calendar-60"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Carpeta calendario</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title" *ngIf="folderCode">{{folderActual.folderName}}</h4>
                    <h4 class="card-title" *ngIf="!folderCode">{{project.projectName}}</h4>
                    <h6 class="category">Puedes buscar carpetas otro nivel utilizando la casilla "Search records"</h6>
                </div>
                <div class="card-body">
                    <div class="toolbar">
                        <!--        Here you can write extra buttons/actions for the toolbar              -->
                    </div>
                    <div class="dataTables_wrapper">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="datatable_length">
                                    <label>
                                        Mostrar
                                        <select name="datatable_length" aria-controls="datatable"
                                            class="form-control form-control-sm" (change)="entriesChange($event)">
                                            <option value="10" [selected]="entries==10">10</option>
                                            <option value="25" [selected]="entries==25">25</option>
                                            <option value="50" [selected]="entries==50">50</option>
                                            <option value="-1" [selected]="entries==-1">All</option>
                                        </select>
                                        entradas
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="datatable_filter" class="dataTables_filter">
                                    <label>
                                        <input type="search" class="form-control form-control-sm"
                                            placeholder="Search records" aria-controls="datatable"
                                            (keyup)="filterTable($event)" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">
                            <ngx-datatable-column name="Nombre"></ngx-datatable-column>
                            <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true" [resizeable]="true">
                                <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                </ng-template>
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                    <a *ngIf="row.file == 0 && row.folderType == 1" class="btn btn-round btn-info btn-icon btn-sm like" (click)="goToCalendar($event);"><i class="fas fa-eye"></i></a>
                                    <a *ngIf="row.file == 0 && row.folderType == 0" class="btn btn-round btn-info btn-icon btn-sm like" (click)="getNext($event);"><i class="fas fa-eye"></i></a>
                                    <a *ngIf="row.file == 1" [href]="row.fileSignUrl" target="_blank" class="btn btn-round btn-success btn-icon btn-sm"><i
                                        class="now-ui-icons arrows-1_cloud-download-93"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event);" *ngIf="userRole == 1 && row.file == 0"><i class="fas fa-times"></i></a>
                                    <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunctionFile($event);" *ngIf="userRole == 1 && row.file == 1"><i class="fas fa-times"></i></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                </div>
                <!-- end content-->
            </div>
            <!--  end card  -->
        </div>
        <!-- end col-md-12 -->
    </div>
    <!-- modal -->
    <ng-template #classic let-c="close" let-d="dismiss">
        <div class="modal-header justify-content-center">
            <button type="button" class="close" (click)="d('Cross click')">
                    <i class="now-ui-icons ui-1_simple-remove"></i>
                </button>
            <h4 class="title title-up">Código QR</h4>
        </div>
        <div class="modal-body">
            <p>Por favor, toma un print del código e integralo en el informe antes de subirlo</p>
            <div class="row text-center">
                <div class="col-md-8 mx-auto">
                    <img src='{{imageQR}}'>
                </div>
            </div>
        </div>
        <div class="modal-footer text-right">
            <button type="button" class="btn btn-danger" (click)="c('Close click')">
                    Cerrar
                </button>
        </div>
    </ng-template>
</div>