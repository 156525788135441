import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { ProjectService } from "src/app/services/project.service";
import { Project } from "src/app/models/project";
import { User } from "src/app/models/user";
import { Client } from "src/app/models/client";
import { ClientService } from "src/app/services/client.service";
import { ConsoleLogger } from "@aws-amplify/core";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
  providers: [ProjectService, ClientService]
})

export class ProjectComponent implements OnInit {
  loading;
  // variables de usuario y modelos
  public userRole: number;
  public userCode: number;
  public isEdit: boolean;
  public project: Project;
  public projectList: Project[];
  public client: Client;
  public isDisabled: boolean;

  // variables para mostrar u ocultar el contenedor
  public contentPrincipal: boolean;
  public contentCrudProject: boolean;
  public contentCrudFolder: boolean;
  public contentCrudFile: boolean;

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public temp = [];
  public activeRow: any;

  constructor(
    private _projectService: ProjectService,
    private toastr: ToastrService,
    private router: Router,
    private _route: ActivatedRoute,
    private _clientService: ClientService,
  ) {
    this.project = new Project(null, null, null, null, null, null, null, null, null);
    this.client = new Client(null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  async ngOnInit() {
    let _user = {} as User;
    if (localStorage.getItem('user')) {
      _user = JSON.parse(localStorage.getItem('user'));
    }

    this.userRole = _user.userRole ? parseInt(_user.userRole.toString()) : 0;
    this.userCode = _user.userCode ? parseInt(_user.userCode.toString()) : 0;

    this.isDisabled = false;

    if(this.userRole == 1){

      this._route.paramMap.subscribe(async (params: any) => {
        const clientCode = params.params.clientCode;
        const viewCode = params.params.viewCode;
  
        this.project.projectUser = this.userCode;
        this.project.projectClient = clientCode;

        // console.log(this.project.projectClient);
  
        await this.getClient(clientCode);
  
        if (viewCode == 0) {
          this.router.navigate(
            ['project/' + this.project.projectClient]
          );
        }
  
      });

    }else if(this.userRole == 2){
      this.project.projectClient = _user.userClient ? parseInt(_user.userClient.toString()) : 0;
    }

    // inicialización de contenedor
    this.contentPrincipal = true;

    // inicialización de variables de usuario y modelos
    this.project.projectBusinessCode = null;


    // funciones iniciales
    this.getProjectByClient(this.project.projectClient);

  }

  // funciones para cambiar de contenedor
  async goToContentPrincipal() {
    this.contentPrincipal = true;
    this.contentCrudProject = false;
    this.contentCrudFolder = false;
    this.contentCrudFile = false;
    this.isEdit = false;
    this.cleanModel();
  }

  goToContentCrudProject() {
    this.contentPrincipal = false;
    this.contentCrudProject = true;
    this.contentCrudFolder = false;
    this.contentCrudFile = false;
    
  }

  getFolders($event) {
    $event.preventDefault();

    //console.log(this.activeRow.projectCode);

    this.router.navigate(
      ['/folder/' + this.activeRow.projectCode]
    );
  }

  // funcion para obtener datos del cliente
  getClient(clientCode) {
    this.loading = true;
    //console.log(clientCode);
    return this._clientService.getOne(clientCode).then(
      response => {
        if (response.result == true) {
          this.client = response.records[0];
          // console.log(response);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }
  // funciones para datatable
  getProjectByClient(projectClient) {
    this.loading = true;
    this._projectService.getAllByClient(projectClient).then(
      response => {
        if (response.result == true) {
          this.projectList = response.records;
          this.temp = this.projectList;
          //console.log(response);
        }
      },
      error => {
        console.log(error);
      }
      ).finally(() => {
        this.loading = false;
      });
    }

  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value;
    this.temp = this.projectList.filter(function (d) {

      for (var key in d) {
        //console.log(d[key]);

        if(d[key] !== null){

          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }

        }

      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  goToClient() {


  }

  // funciones para manipulación de datos
  save() {
    this.isDisabled = true;

    this.project.projectStatus = 1;

    //console.log(this.project);
    this._projectService.insert(this.project).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          //limpiar el modelo
          this.cleanModel();

          // Realizar la actualización de la tabla
          this.getProjectByClient(this.project.projectClient);

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Ir hacia outbox
          this.goToContentPrincipal();

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );

  }

  editFunction($event) {
    $event.preventDefault();
    this.isEdit = true;

    this.project.projectCode = this.activeRow.projectCode;
    this.project.projectName = this.activeRow.projectName;
    this.project.projectDescription = this.activeRow.projectDescription;

    this.goToContentCrudProject();

  }

  update() {
    this.isDisabled = true;

    this.project.projectStatus = 1;

    // console.log(this.project);
    this._projectService.update(this.project).subscribe(
      async (response) => {
        if (response.result == true) {
          // console.log(response);

          //limpiar el modelo
          this.cleanModel();

          // Realizar la actualización de la tabla
          this.getProjectByClient(this.project.projectClient);

          // Mostrar notificación
          this.showNotification(1, response.message);

          // Ir hacia outbox
          this.goToContentPrincipal();

        } else {

          // limpiar el modelo
          this.cleanModel();

          // Mostrar notificación
          this.showNotification(3, response.errorMessage);
          // console.log(response);
        }
      },
      error => {
        // Mostrar notificacion
        this.showNotification(2, error);
        console.log(error);
      }
    );
  }

  deleteFunction($event) {
    $event.preventDefault();

    this.project.projectCode = this.activeRow.projectCode;
    this.project.projectBusinessCode = undefined;
    this.project.projectUser = this.activeRow.projectUser;
    this.project.projectClient = this.activeRow.projectClient;
    this.project.projectName = undefined;
    this.project.projectDescription = undefined;
    this.project.projectStatus = undefined;
    this.project.projectCreationDate = undefined;
    this.project.projectDeleted = 1;

    //console.log(this.client);

    swal.fire({
      title: "Esta seguro",
      text: "El proyecto se elminara definitivamte!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Si, eliminar!",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        this.update();
        this.getProjectByClient(this.project.projectClient);
        swal.fire({
          title: "Eliminado!",
          text: "El proyecto se ha eliminado.",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false
        });
      }
    });

  }

  cleanModel() {
    this.isDisabled = false;
    
    this.project.projectCode = null;
    this.project.projectName = null;
    this.project.projectDescription = null;
    this.project.projectDeleted = null;
  }

  // Notificaciónes
  showNotification(type, message) {

    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }


}
