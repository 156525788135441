<div class="panel-header panel-header-sm">

</div>
<!-- Contenedor bandeja de entrada -->
<div class="main-content" *ngIf="contentInbox">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-12">
                            <button class="btn btn-default btn-block btn-lg mt-10" (click)="getSend();">
                                <span class="btn-label">
                                    <i class="now-ui-icons ui-1_simple-add"></i>
                                </span>
                                &nbsp; Nuevo
                            </button>
                            <button class="btn btn-success btn-block btn-lg btn-simple mt-10" (click)="getInbox();">
                                <span class="btn-label">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                </span>
                                &nbsp; Entrantes
                            </button>
                            <button class="btn btn-default btn-block btn-lg btn-simple mt-10" (click)="getOutbox();">
                                <span class="btn-label">
                                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </span>
                                &nbsp; Salientes
                            </button>
                        </div>
                        <div class="col-md-9 col-12">
                            <h4 class="title title-up mt-1">Bandeja de entrada</h4>
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Mostrar
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm" (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entradas
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">

                                    <ngx-datatable-column name="Remitente"></ngx-datatable-column>
                                    <ngx-datatable-column name="Usuario"></ngx-datatable-column>
                                    <ngx-datatable-column name="Asunto"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="false" [resizeable]="true">
                                        <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <a href="#" [class]="row.messageReadDate === null ? 'btn btn-round btn-success btn-icon btn-sm like': 'btn btn-round btn-default btn-icon btn-sm like'" (click)="getRead($event)"><i class="fas fa-eye"></i></a>
                                            <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"><i class="fas fa-times"></i></a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contenedor bandeja de salida -->
<div class="main-content" *ngIf="contentOutbox">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-12">
                            <button class="btn btn-default btn-block btn-lg mt-10" (click)="getSend();">
                                <span class="btn-label">
                                    <i class="now-ui-icons ui-1_simple-add"></i>
                                </span>
                                &nbsp; Nuevo
                            </button>
                            <button class="btn btn-default btn-block btn-lg btn-simple mt-10" (click)="getInbox();">
                                <span class="btn-label">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                </span>
                                &nbsp; Entrantes
                            </button>
                            <button class="btn btn-success btn-block btn-lg btn-simple mt-10" (click)="getOutbox();">
                                <span class="btn-label">
                                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                </span>
                                &nbsp; Salientes
                            </button>
                        </div>
                        <div class="col-md-9 col-12">
                            <h4 class="title title-up mt-1">Bandeja de salida</h4>
                            <div class="dataTables_wrapper">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <div class="dataTables_length" id="datatable_length">
                                            <label>
                                                Mostrar
                                                <select name="datatable_length" aria-controls="datatable"
                                                    class="form-control form-control-sm" (change)="entriesChange($event)">
                                                    <option value="10" [selected]="entries==10">10</option>
                                                    <option value="25" [selected]="entries==25">25</option>
                                                    <option value="50" [selected]="entries==50">50</option>
                                                    <option value="-1" [selected]="entries==-1">All</option>
                                                </select>
                                                entradas
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div id="datatable_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search records" aria-controls="datatable"
                                                    (keyup)="filterTable($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <ngx-datatable class="bootstrap selection-cell" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries:undefined" [rows]="temp" (activate)="onActivate($event)">

                                    <ngx-datatable-column name="Destinatario"></ngx-datatable-column>
                                    <ngx-datatable-column name="Usuario"></ngx-datatable-column>
                                    <ngx-datatable-column name="Asunto"></ngx-datatable-column>
                                    <ngx-datatable-column [sortable]="true" [canAutoResize]="true" [draggable]="true" [resizeable]="true">
                                        <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                            Acciones
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                            <a href="#" class="btn btn-round btn-default btn-icon btn-sm like" (click)="getRead($event)"><i class="fas fa-eye"></i></a>
                                            <a href="#" class="btn btn-round btn-danger btn-icon btn-sm remove" (click)="deleteFunction($event)"><i class="fas fa-times"></i></a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contenedor nuevo mensaje -->
<div class="main-content" *ngIf="contentSend">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-12">
                            <button class="btn btn-default btn-block btn-lg mt-10" (click)="getInbox();">
                                <span class="btn-label">
                                    <i class="fa fa-arrow-left"></i>
                                </span>
                                &nbsp; Bandeja de entrada
                            </button>
                        </div>
                        <div class="col-md-9 col-12">
                            <h4 class="title title-up mt-1">Mensaje nuevo</h4>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>Dirigido a:</label>
                                    <div class="form-group" *ngIf="userRole === 1">
                                        <angular2-multiselect [data]="userList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                                        </angular2-multiselect>
                                    </div>
                                    <div class="form-group" *ngIf="userRole !== 1">
                                        <input type="text" class="form-control" [(ngModel)]="message.messageTo" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Asunto:</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="message.messageSubject" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Mensaje</label>
                                        <textarea [(ngModel)]="message.messageBody" rows="4" cols="80" class="form-control" placeholder="Escribe aquí tu mensaje"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group text-right">
                                        <button class="btn btn-success" (click)="save();">
                                            <span class="btn-label">
                                              <i class="now-ui-icons ui-1_check"></i>
                                            </span>
                                            Enviar
                                          </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contenedor leer mensaje -->
<div class="main-content" *ngIf="contentRead">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 col-12">
                            <button class="btn btn-default btn-block btn-lg mt-10" (click)="getInbox();">
                                <span class="btn-label">
                                    <i class="fa fa-arrow-left"></i>
                                </span>
                                &nbsp; Bandeja de entrada
                            </button>
                        </div>
                        <div class="col-md-9 col-12">
                            <h4 class="title title-up mt-1">Leer mensaje</h4>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>De:</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="message.de" disabled />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Para:</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="message.para" disabled/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Asunto:</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="message.messageSubject" disabled/>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Mensaje</label>
                                        <textarea [(ngModel)]="message.messageBody" rows="4" cols="80" class="form-control" disabled></textarea>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12" *ngIf="!contentAnswer">
                                    <div class="form-group text-right">
                                        <button class="btn btn-success" (click)="getAnswer();">
                                            <span class="btn-label">
                                              <i class="now-ui-icons ui-1_check"></i>
                                            </span>
                                            Responder
                                          </button>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="contentAnswer">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label>Respuesta</label>
                                            <textarea [(ngModel)]="message.messageAnswer" rows="4" cols="80" class="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="contentAnswer">
                                    <div class="form-group text-right">
                                        <button class="btn btn-success">
                                            <span class="btn-label">
                                              <i class="now-ui-icons ui-1_check"></i>
                                            </span>Enviar
                                          </button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>