export class Client{
	constructor(
		public clientCode: number,
        public clientType: number,
        public clientCompany: string,
        public clientDocument: string,
        public clientName: string,
        public clientEmail: string,
        public clientPhone: string,
        public clientCreationDate: string,
        public clientUpdateDate: string,
        public clientDeleted: number,
        public clientTaxDocument: string,
        public clientAddress: string,
        public clientStatus: number
	){}
}
