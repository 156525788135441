export class User{
	constructor(
        public userClient: number,
		public userCode: number,
        public userName: string,
        public userEmail: string,
        public userRole: number,
        public userCognitoCode: string,
        public userStatus: number,
        public userDeleted: number,
        public userPassword: string,
        public userPhone: string
	){}
}