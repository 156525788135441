<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
</div>
<!-- Contenedor inicial de Folders -->
<div class="main-content">
    <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row">
                        <ngx-doc-viewer [url]="url" viewer="google" style="width:100%;height:93vh;">
                        </ngx-doc-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>