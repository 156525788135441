import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Project } from '../models/project';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProjectService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
  }

  // Obtiene el listado de todos los proyectos
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers }).toPromise();
  }

  // Obtiene el listado de proyectos por cliente
  getAllByClient(clientCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-project/client/' + clientCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de proyectos solo codigo y nombre
  getAllList(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers });
  }

  // Obtiene un solo proyecto a la vez
  getOne(projectCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-project/one/' + projectCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Inserta un proyecto
  insert(project: Project): Observable<any> {
    let params = JSON.stringify(project);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'isocretosystem-project', params, {
      headers: headers,
    });
  }

  // Actualiza un proyecto
  update(project: Project): Observable<any> {
    let params = JSON.stringify(project);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url + '/isocretosystem-project', params, {
      headers: headers,
    });
  }
}
