import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Folder } from '../models/folder';

import { File } from '../models/file';
import { environment } from 'src/environments/environment';

@Injectable()
export class FileService {
  public url: string;
  public urlQR: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
    this.urlQR = environment.qrUrl;
  }

  // Obtiene la imagen QR
  getQR(fileQR): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.urlQR + fileQR, { headers: headers });
  }

  // Obtiene el listado de todos los archivos
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers }).toPromise();
  }

  // Obtiene el listado de archivos por folder
  getAllByFolder(folderCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-file/folder/' + folderCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de archivos por proyecto
  getAllByProject(folderCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-file/folder/' + folderCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de archivos por cliente
  getAllByClient(clientCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-file/client/' + clientCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de archivos solo codigo y nombre
  getAllList(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '', { headers: headers }).toPromise();
  }

  // Obtiene un solo archivo a la vez
  getOne(fileCode): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + '' + fileCode, { headers: headers });
  }

  // Obtiene un solo archivo a la vez por medio de QR
  getOneByQR(fileCode): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.get(this.url + 'isocretosystem-file/qr/' + fileCode, {
      headers: headers,
    });
  }

  // Inserta un archivo
  insert(file: File): Observable<any> {
    let params = JSON.stringify(file);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'isocretosystem-file', params, {
      headers: headers,
    });
  }

  updateV2(url, fileData): Promise<any> {
    // let params = fileData; //JSON.stringify(file);
    let headers = new HttpHeaders().set('Content-Type', 'application/pdf');

    return this._http.put(url, fileData, {
      headers: headers,
    }).toPromise();
  }

  // Actualiza un archivo
  update(file: File): Observable<any> {
    let params = JSON.stringify(file);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url + 'isocretosystem-file', params, {
      headers: headers,
    });
  }
}
