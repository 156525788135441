<app-loading *ngIf="loading"></app-loading>
<div class="panel-header panel-header-sm">
    <div class="row link-header ml-3">
        <div>
            <a routerLink="/home">Home&nbsp;</a>
        </div>
        <div *ngIf="userRole == 1">
            <div *ngIf="client.clientType == 1">
                /<a routerLink="/client/0"> Clientes </a>/<a routerLink="/client/1"> Individuales&nbsp;</a>
            </div>
            <div *ngIf="client.clientType == 2">
                /<a routerLink="/client/0"> Clientes </a>/<a routerLink="/client/2"> Juridicos&nbsp;</a>
            </div>
        </div>
        <div>
            /<a routerLink="/project/{{project.projectClient}}"> Proyectos &nbsp;</a>
        </div>
        <div *ngIf="!folderCode">
            / <a> {{project.projectName}} </a>
        </div>
        <div *ngIf="folderCode">
            / <a routerLink="/folder/{{project.projectCode}}"> {{project.projectName}} </a> / <a>
                {{folderActual.folderName}} </a>
        </div>
    </div>
</div>
<!-- Contenedor inicial de Folders -->
<div class="main-content">
    <div class="row">
        <div class="col-md-10 ml-auto mr-auto">
            <div class="card card-stats">
                <div class="card-body">
                    <div class="row" *ngIf="userRole == 1">
                        <div class="col-md-2">
                            <div class="statistics">
                                <a routerLink="/project/{{project.projectClient}}">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="fa fa-arrow-left fa-2x"></i>
                                        </div>
                                        <h6 class="stats-title">Atras</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="statistics">
                                <div class="row">
                                    <div class="col-sm-9">
                                        <h6 class="stats-title">Cargar archivo con QR</h6>
                                    </div>
                                    <div class="col-sm-2">
                                        <bSwitch [switch-on-color]="'primary'" (click)="getUploadType();" switch-on-text="<i class='now-ui-icons ui-1_check'></i>" switch-off-text="<i class='now-ui-icons ui-1_simple-remove'></i>">
                                        </bSwitch>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-9" *ngIf="!contentQR">
                                        <div class="custom-input-file">
                                            <input type="file" (change)="addFile($event)" #input class="input-file" /> Subir fichero...
                                        </div>
                                    </div>
                                    <div class="col-sm-9" *ngIf="contentQR && !qrGenerado">
                                        <button class="btn btn-default btn-round mt-0" (click)="getUploadQR(classic);">Generar QR</button>
                                    </div>
                                    <div class="col-sm-9" *ngIf="contentQR && qrGenerado">
                                        <div class="custom-input-file">
                                            <input type="file" (change)="addFile($event)" #input class="input-file" /> Subir fichero...
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <button class="btn btn-success btn-round mt-0" (click)="saveFile();" [disabled]="isDisabled">Cargar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="statistics">
                                <div class="col-sm-12">
                                    <h6 class="stats-title">Fecha de carga</h6>
                                </div>
                                <div class="datepicker-container">
                                    <div class="form-group">
                                        <input class="form-control datetimepicker" name="dp" [(ngModel)]="file.fileDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" placeholder="{{now}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row" *ngIf="userRole == 2">
                        <div class="col-md-12">
                            <div class="statistics">
                                <a (click)="goToCalendar();">
                                    <div class="info">
                                        <div class="icon icon-default">
                                            <i class="now-ui-icons ui-1_calendar-60"></i>
                                        </div>
                                        <br />
                                        <h6 class="stats-title">Carpeta calendario</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 ml-auto mr-auto">
            <div class="card card-calendar">
                <div class="card-body">
                    <div id='calendar'></div>
                    <!-- <div id='destination-calendar'></div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- modal -->
    <ng-template #classic let-c="close" let-d="dismiss">
        <div class="modal-header justify-content-center">
            <button type="button" class="close" (click)="d('Cross click')">
                <i class="now-ui-icons ui-1_simple-remove"></i>
            </button>
            <h4 class="title title-up">Código QR</h4>
        </div>
        <div class="modal-body">
            <p>Por favor, toma un print del código e integralo en el informe antes de subirlo</p>
            <div class="row text-center">
                <div class="col-md-8 mx-auto">
                    <img src='{{imageQR}}'>
                </div>
            </div>
        </div>
        <div class="modal-footer text-right">
            <button type="button" class="btn btn-danger" (click)="c('Close click')">
                Cerrar
            </button>
        </div>
    </ng-template>
</div>