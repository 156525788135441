import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Message } from '../models/message';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessageService {
  public url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.apiUrl;
  }

  // Obtiene el listado de mensajes completo
  getAll(): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-message', { headers: headers })
      .toPromise();
  }

  // Obtiene el listado de mensajes entrantes
  getInbox(userCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-message/to/' + userCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene el listado de mensajes entrantes
  getOutbox(userCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-message/from/' + userCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Obtiene un mensaje para leer
  getOne(messageCode): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http
      .get(this.url + 'isocretosystem-message/one/' + messageCode, {
        headers: headers,
      })
      .toPromise();
  }

  // Inserta un mensaje
  insert(message: Message): Observable<any> {
    let params = JSON.stringify(message);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.post(this.url + 'isocretosystem-message', params, {
      headers: headers,
    });
  }

  // Actualiza un mensaje a eliminado
  update(message: Message): Observable<any> {
    let params = JSON.stringify(message);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this._http.put(this.url + 'isocretosystem-message', params, {
      headers: headers,
    });
  }
}
