import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { User } from "src/app/models/user";
import { UserService } from "src/app/services/user.service";

var misc: any = {
  sidebar_mini_active: true
}

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const RoutesAdmin: RouteInfo[] = [
  {
    path: "/home",
    title: "Tablero",
    type: "link",
    icontype: "tech_tv"
  },
  {
    path: "/",
    title: "Mantenimiento",
    type: "sub",
    icontype: "ui-1_settings-gear-63",
    collapse: "maintenance",
    isCollapsed: true,
    children: [
      { path: "client", title: "Clientes", ab: "CL" },
      { path: "user", title: "Usuarios", ab: "US" }
    ]
  },
  // {
  //   path: "/",
  //   title: "Administrativo",
  //   type: "sub",
  //   icontype: "objects_globe",
  //   collapse: "admin",
  //   isCollapsed: true,
  //   children: [
  //     { path: "work-order", title: "Orden de Trabajo", ab: "OT" },
  //     // { path: "user", title: "Usuarios", ab: "US" }
  //   ]
  // },
  {
    path: "/",
    title: "Operacional",
    type: "sub",
    icontype: "tech_laptop",
    collapse: "operative",
    isCollapsed: true,
    children: [
      { path: "qr-validate", title: "Validación QR", ab: "QR" },
      { path: "contact", title: "Contacto", ab: "CN" },
    ]
  }
];

export const RoutesClient: RouteInfo[] = [
  {
    path: "/home",
    title: "Tablero",
    type: "link",
    icontype: "tech_tv"
  },
  {
    path: "/",
    title: "Operacional",
    type: "sub",
    icontype: "tech_laptop",
    collapse: "operative",
    isCollapsed: true,
    children: [
      { path: "project", title: "Proyectos", ab: "PR" },
      { path: "qr-validate", title: "Validación QR", ab: "QR" },
      { path: "contact", title: "Contacto", ab: "CN" },
    ]
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public user = {} as User;
  public role: number;

  constructor(private toastr: ToastrService, private userService: UserService,) { }

  async ngOnInit() {
    await this.startValidation();

  }

  startValidation() {
    if (localStorage.getItem('user') !== null) {
      this.user = JSON.parse(localStorage.getItem('user'));
    } else {
      // this.logOut();
    }
    if (this.user) {
      if (this.user !== undefined) {
        this.userService.oneUserCognitoBackend(this.user.userCognitoCode)
          .then(
            (result) => {
              const userDB = result.records[0];
              if (userDB !== undefined) {
                userDB.userPassword = undefined;
                localStorage.setItem('user', JSON.stringify(userDB));

                if(userDB.userRole == 1){
                  this.menuItems = RoutesAdmin.filter(menuItem => menuItem);
                }else{
                  this.menuItems = RoutesClient.filter(menuItem => menuItem);
                }


              } else {
                this.logOut();
              }
            }
          ).catch(
            (err) => {
              console.log(err);
            }
          );
      }
    }
  }

  logOut() {
    this.userService.logout();
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        var style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function () {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function () {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function () {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function () {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function () {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
      this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
      body.classList.add('sidebar-mini');
      this.showSidebarMessage('Sidebar mini activated...');
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>', message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }
}
