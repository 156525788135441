import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from 'ngx-toastr';
import { FooterComponent } from './core/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ProjectComponent } from './pages/project/project.component';
import { FolderComponent } from './pages/folder/folder.component';
import { CalendarFolderComponent } from './pages/calendar-folder/calendar-folder.component';
import { FileComponent } from './pages/file/file.component';
import { QrValidateComponent } from './pages/qr-validate/qr-validate.component';
import { ViewPdfComponent } from './pages/view-pdf/view-pdf.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TagInputModule } from "ngx-chips";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { ArchwizardModule } from "angular-archwizard";
import { NgQrScannerModule } from 'angular2-qrscanner';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { HttpClientModule } from '@angular/common/http';
import { ContactComponent } from './pages/contact/contact.component';
import { ClientComponent } from './pages/client/client.component';
import { UserComponent } from './pages/user/user.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth-guard.service';
import { UserAutoConfirmComponent } from './pages/auth/user-auto-confirm/user-auto-confirm.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './pages/profile/profile.component';
import { WorkOrderComponent } from './pages/work-order/work-order.component';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    HomeComponent,
    ProjectComponent,
    FolderComponent,
    FileComponent,
    QrValidateComponent,
    ViewPdfComponent,
    ContactComponent,
    ClientComponent,
    UserComponent,
    LoginComponent,
    UserAutoConfirmComponent,
    CalendarFolderComponent,
    ProfileComponent,
    WorkOrderComponent,
    LoadingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgxDocViewerModule,
    NgxDatatableModule,
    NgQrScannerModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    NgbModule,
    JwBootstrapSwitchNg2Module,
    AngularMultiSelectModule,
    ArchwizardModule,
    AlifeFileToBase64Module,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    
  ],
  providers: [UserService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
